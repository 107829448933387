import * as yup from 'yup';

const upsertCatchmentAreasFormSchema = yup.object({
  stage: yup
    .string()
    .oneOf(['PRIMARY', 'SECONDARY'])
    .required()
    .label('Year Stage'),
  providerType: yup
    .string()
    .oneOf(['NON_DENOMINATIONAL', 'ROMAN_CATHOLIC'])
    .required()
    .label('Provider Type'),
  catchmentAreaFiles: yup
    .array(
      yup.object({
        filename: yup.string(),
        reference: yup.string(),
        key: yup.string().required(),
      })
    )
    .required()
    .max(1, 'Catchment Area can only have 1 file'),
});

export { upsertCatchmentAreasFormSchema };
