import { ApplicantNameSearchBar } from '@/components/application/applicant-name-searchbar';
import { ApplicationsFilterDrawer } from '@/components/application/applications-filter-drawer';
import { ApplicationsFilterRow } from '@/components/application/applications-filter-row';
import { ApplicationsList } from '@/components/application/applications-list';
import { MatchingModal } from '@/components/application/matching-modal';
import { PermissionGuard } from '@/components/auth/permission-guard';
import { NewApplicationModal } from '@/components/new-application.modal';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { staffRouterPath } from '@/config/route-paths.config';
import { ApplicationControlProvider } from '@/context/application-control.context';
import { Permission } from '@/types/auth';
import { LocationType } from '@admissions-support/types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { matchPath } from 'react-router-dom';
import { BulkUploadModal } from '@/components/bulk-upload.modal';
import { BulkActionModal } from '@/components/application/bulk-action/bulk-action-modal';

function ApplicationsSecondary() {
  const { pathname } = useLocation();
  const isBulkUploadModal = Boolean(
    matchPath(staffRouterPath.APPLICATIONS_SECONDARY_BULK_UPLOAD, pathname)
  );

  const [openNewApplicationModal, setOpenNewApplicationModal] = useState(false);
  const [selectedApplicationIds, setSelectedApplicationIds] = useState<
    string[] | null
  >(null);
  const navigate = useNavigate();

  const handleOpenActionModal = (applicationIds: string[]) => {
    setSelectedApplicationIds(applicationIds);
  };

  const handleCloseActionModal = () => {
    setSelectedApplicationIds(null);
  };

  const openBulkUploadModal = () => {
    navigate(staffRouterPath.APPLICATIONS_SECONDARY_BULK_UPLOAD);
  };

  const closeBulkUploadModal = () => {
    navigate(staffRouterPath.APPLICATIONS_SECONDARY);
  };

  return (
    <>
      <PageTitle title="Applications" variant="gray">
        <PermissionGuard
          requiredPermissions={[Permission['application:create']]}
        >
          <div className="space-x-2 sm:ml-auto">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setOpenNewApplicationModal(true);
              }}
            >
              New Application
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              onClick={openBulkUploadModal}
            >
              Bulk upload
            </button>

            <MatchingModal />
          </div>
        </PermissionGuard>
      </PageTitle>
      <ApplicationControlProvider type="secondary_main">
        <div className="!mt-12 flex flex-col space-y-2 sm:flex-row sm:items-center sm:space-y-0">
          <div className="flex space-x-2 sm:mr-2">
            <SchoolYearFilter />

            <ApplicationsFilterDrawer
              applicationsType={'SECONDARY' as LocationType}
            />
          </div>
          <div className="sm:ml-auto">
            <ApplicantNameSearchBar />
          </div>
        </div>
        <ApplicationsFilterRow applicationsType={'SECONDARY' as LocationType} />
        <ApplicationsList
          onActionApply={handleOpenActionModal}
          applicationsType={'SECONDARY' as LocationType}
        />
      </ApplicationControlProvider>

      <BulkUploadModal
        open={isBulkUploadModal}
        onClose={closeBulkUploadModal}
        applicationType={'SECONDARY' as LocationType}
      />

      <BulkActionModal
        open={!!selectedApplicationIds}
        onClose={handleCloseActionModal}
        selectedApplicantIds={selectedApplicationIds || []}
        applicationsType={'SECONDARY' as LocationType}
      />

      <NewApplicationModal
        open={openNewApplicationModal}
        onClose={() => {
          setOpenNewApplicationModal(false);
        }}
      />
    </>
  );
}

export { ApplicationsSecondary };
