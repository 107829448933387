type DecisionAnalysisItemSubject = {
  label: string;
  value: string;
};

function DecisionAnalysisReportItemSubject({
  label,
  value,
}: DecisionAnalysisItemSubject) {
  return (
    <div className="flex flex-col  gap-2 bg-white p-4" key={value}>
      <div className="flex flex-col">
        <span className="text-sm  text-gray-900">Catchment Children</span>
        <span className="text-sm  text-gray-900">
          Baptised into the Denominational faith
        </span>
        <span className="text-sm  text-gray-900">
          {' '}
          Attending associated primary schools
        </span>
      </div>

      <div className="mt-2 flex gap-4">
        <div className="flex flex-col">
          <span className="text-xs text-gray-700">
            with siblings in attendance at St Margarets Academy
          </span>
          <span className="text-md mt-2 font-medium">100</span>
        </div>
        <div className="flex flex-col">
          <span className="text-xs text-gray-700">
            with no siblings in attendance at St Margarets Academy
          </span>
          <span className="text-md mt-2 font-medium">100</span>
        </div>
      </div>
    </div>
  );
}

type DecisionAnalysisReportItemProps = {
  title: string;
  subjects: DecisionAnalysisItemSubject[];
};

function DecisionAnalysisReportItem({
  title,
  subjects,
}: DecisionAnalysisReportItemProps) {
  return (
    <div className="rounded-lg border border-gray-200 bg-gray-50 p-4">
      <span className="text-sm font-medium text-gray-900">{title}</span>
      <div className="mt-2 flex flex-col gap-2">
        {subjects.map(subject => (
          <DecisionAnalysisReportItemSubject
            label={subject.label}
            value={subject.value}
            key={subject.value}
          />
        ))}
      </div>
    </div>
  );
}

function DecisionAnalysisReportResult() {
  return (
    <div className="flex flex-col gap-6">
      <DecisionAnalysisReportItem
        title="Accepted"
        subjects={[
          { label: 'Primary', value: 'primary' },
          { label: 'Secondary', value: 'secondary' },
        ]}
      />
    </div>
  );
}

export default DecisionAnalysisReportResult;
