import { Select } from './form/common/select';

const yearStages = [
  { label: 'Primary 1', value: 'primaryOne' },
  { label: 'Primary 2', value: 'primaryTwo' },
  { label: 'Primary 3', value: 'primaryThree' },
  { label: 'Primary 4', value: 'primaryFour' },
  { label: 'Primary 5', value: 'primaryFive' },
  { label: 'Primary 6', value: 'primarySix' },
  { label: 'Primary 7', value: 'primarySeven' },
  { label: 'Secondary 1', value: 'secondaryOne' },
  { label: 'Secondary 2', value: 'secondaryTwo' },
  { label: 'Secondary 3', value: 'secondaryThree' },
  { label: 'Secondary 4', value: 'secondaryFour' },
  { label: 'Secondary 5', value: 'secondaryFive' },
  { label: 'Secondary 6', value: 'secondarySix' },
];

type YearStageSelectProps = {
  disabled?: boolean;
};

function YearStageSelect({ disabled }: YearStageSelectProps) {
  return (
    <Select
      name="yearStage"
      label="Year Stage"
      options={yearStages.map(stage => ({
        key: stage.value,
        value: stage.label,
      }))}
      disabled={disabled}
    />
  );
}

export { YearStageSelect };
