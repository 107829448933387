import { getChoiceName } from '@/components/application-quick-view-drawer';
import { Toggle } from '@/components/form/common/toggle';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateChoiceMutation } from '@/hooks/update-hooks/use-update-choice-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { transformScore } from '@/utils/application-utils';
import {
  Choice,
  LocationProviderType,
  LocationType,
  SchoolApplicationChoice,
  SchoolLocationType,
} from '@admissions-support/types';
import { compact, startCase } from 'lodash-es';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ChoiceStatusTag } from '../choice-status-tag';
import { LocationChoice } from '../location-choice';
import { isNotSchoolChoice } from '@/utils/choice-utils';
import { useSchoolLocation } from '@/hooks/query-hooks/use-school-location';
import { Spinner } from '@/components/spinner';
import { Alert } from '@/components/alert';
import { getSchoolProviderType } from '@/utils/location-utils';
import { ProviderTypeBadge } from '../provider-type-badge';

type ChoiceFieldProps = {
  choice: Choice | SchoolApplicationChoice;
  schoolYearId: string;
  applicationId: string;
  applicationsType: LocationType;
};

function ChoiceField(props: ChoiceFieldProps) {
  const { choice, schoolYearId, applicationId, applicationsType } = props;
  const [isReserved, setIsReserved] = useState(choice.status === 'RESERVED');
  const { mutateAsync: updateChoice } = useUpdateChoiceMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['application', applicationId],
      });
      queryClient.invalidateQueries({
        queryKey: ['application-list'],
      });
    },
  });
  const { hasPermission } = usePermissions();

  const {
    data: school,
    isLoading,
    isError,
    isSuccess,
  } = useSchoolLocation(choice.location.id.toString(), {
    enabled: applicationsType !== 'ELC',
  });

  const params = useParams<{ locationId: string }>();
  if (params.locationId) {
    const idsToMatch = compact([
      choice.location.id,
      'splitPlacement' in choice
        ? choice.splitPlacement?.location?.id.toString()
        : undefined,
    ]);

    if (!idsToMatch.some(id => id === params.locationId)) {
      return null;
    }
  }

  const handleToggleChange = async (value: boolean) => {
    setIsReserved(value);

    try {
      const previousStatus = choice.previousStatus || null;
      await updateChoice({
        applicationId,
        choiceId: choice.id,
        data: {
          status: value ? 'RESERVED' : previousStatus,
        },
      });
    } catch (err) {
      setIsReserved(!value);
    }
  };

  const reserveToggleHiddenStatuses = ['PLACED', 'GRANTED'];

  const isReserveToggleShown =
    choice.status &&
    !reserveToggleHiddenStatuses.includes(choice.status) &&
    hasPermission([Permission['application:update']]);

  const renderProviderTypeBadge = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (isError || !isSuccess) {
      return <Alert type="error" text="Error querying location" />;
    }

    const convertedProviderType = getSchoolProviderType(
      school.type as SchoolLocationType
    );

    if (!convertedProviderType) {
      return null;
    }

    return (
      <ProviderTypeBadge
        providerType={convertedProviderType as LocationProviderType}
      />
    );
  };

  return (
    <div className="light-gray-container relative" key={choice.id}>
      <div className="mb-4 flex justify-between">
        <p className="flex font-medium">
          <span className="mr-2">{getChoiceName(choice)} Choice</span>
          {choice.status ? (
            <ChoiceStatusTag status={choice.status}>
              {startCase(choice.status.toLowerCase())}
            </ChoiceStatusTag>
          ) : null}
        </p>

        <span className="font-mono">
          Score: {transformScore(choice.score) ?? '-'}
        </span>
      </div>
      {isNotSchoolChoice(choice) ? (
        <div className="space-y-3">
          <LocationChoice schoolYearId={schoolYearId} choice={choice} />
        </div>
      ) : (
        <div className="dark-gray-container flex items-center gap-4">
          <p className="text-lg text-gray-900">{choice.location.name}</p>
          <span>{renderProviderTypeBadge()}</span>
        </div>
      )}
      {isReserveToggleShown ? (
        <div className="mt-5">
          <Toggle
            value={isReserved}
            onChange={handleToggleChange}
            label={'Reserve Place'}
            description={
              'Enabling will mark the place as reserved, consuming resource. Disabling will free the resource up to be consumed by other applications.'
            }
          />
        </div>
      ) : null}
    </div>
  );
}

export { ChoiceField };
