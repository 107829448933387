import { ChoiceStatus } from '@admissions-support/types';
import { twMerge } from 'tailwind-merge';
import { Tag } from '../tag';

type ChildChoiceTagProps = {
  status?: ChoiceStatus | 'PROCESSING';
};

const childChoiceStatusText: Record<ChoiceStatus | 'PROCESSING', string> = {
  SCORED: 'Application Received',
  MATCHED: 'Application Processing',
  NOT_MATCHED: 'Application Processing',
  WAITING_LIST: 'Waiting List',
  PLACED: 'Placement Granted',
  RESERVED: 'Placement Reserved',
  PLACEMENT_REJECTED: 'Placement Rejected',
  GRANTED: 'Placement Granted',
  PROCESSING: 'Application Processing',
};

function ChildChoiceStatusTag(props: ChildChoiceTagProps) {
  const { status } = props;

  const tagText = status
    ? childChoiceStatusText[status]
    : 'Application Processing';

  return (
    <Tag
      type="outline"
      className="inline-flex w-auto flex-row items-center px-1.5 py-0.5 text-xs font-normal capitalize shadow-xs"
    >
      <span
        className={twMerge(
          'mr-1 inline-block h-1.5 w-1.5 rounded-full',
          status === 'WAITING_LIST' ? 'bg-yellow-500' : null,
          status === 'MATCHED' ? 'bg-blue-500' : null,
          status === 'PLACED' ? 'bg-green-500' : null,
          status === 'RESERVED' ? 'bg-green-500' : null,
          status === 'PLACEMENT_REJECTED' ? 'bg-red-500' : null,
          status === 'NOT_MATCHED' ? 'bg-gray-500' : null,
          status === 'SCORED' ? 'bg-gray-500' : null,
          status === 'PROCESSING' ? 'bg-blue-500' : null,
          !status ? 'bg-blue-500' : null
        )}
      ></span>
      {tagText}
    </Tag>
  );
}

export { ChildChoiceStatusTag };
