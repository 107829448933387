import { useOrganisationData } from '@/context/organisation-control.context';
import { LocationProviderType } from '@admissions-support/types';
import { startCase } from 'lodash-es';
import { twMerge } from 'tailwind-merge';
import chroma from 'chroma-js';

type ProviderTypeBadgeProps = {
  providerType: LocationProviderType;
};

const colorClasses = {
  PARTNER_PROVIDER: 'text-yellow-700 bg-yellow-50 border-yellow-200',
  COUNCIL: '',
  CHILD_MINDER: 'text-yellow-700 bg-yellow-50 border-yellow-200',
  NON_DENOMINATIONAL: 'text-yellow-700 bg-yellow-50 border-yellow-200',
  DENOMINATIONAL: 'text-yellow-700 bg-yellow-50 border-yellow-200',
};

function ProviderTypeBadge(props: ProviderTypeBadgeProps) {
  const { providerType } = props;

  const {
    data: { brandColour },
  } = useOrganisationData();

  // #027a48 is the color code for green-700
  const colors = chroma
    .scale(['white', brandColour || '#027a48', 'black'])
    .domain([0, 100])
    .colors(50);

  return (
    <span
      className={twMerge(
        'flex w-fit items-center rounded-md border px-[6px] py-[2px] text-xs',
        colorClasses[providerType]
      )}
      style={
        providerType === 'COUNCIL'
          ? {
              color: brandColour,
              backgroundColor: colors[3],
              borderColor: colors[10],
            }
          : {}
      }
    >
      {startCase(providerType?.toLowerCase() || '')}
    </span>
  );
}

export { ProviderTypeBadge };
