import { ApplicationQuickViewDrawer } from '@/components/application-quick-view-drawer';
import { Table } from '@/components/table';
import { useApplicationControl } from '@/context/application-control.context';
import { useApplications } from '@/hooks/query-hooks/use-applications';
import { useModal } from '@/hooks/use-modal';
import { useRatios } from '@/hooks/query-hooks/use-ratios';
import { getScoreByLocation } from '@/utils/application-utils';
import { getErrorMessage } from '@/utils/get-error-message';
import { highlightQuery } from '@/utils/highlight-search-query';
import { ApplicationListing, LocationType } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Eye, SearchRefraction } from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import {
  generatePath,
  matchPath,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ApplicationListFlags } from './application-list-flags';
import { ChoiceTableCell } from './choice-table-cell';
import { compact } from 'lodash-es';
import { formatDateToShortDate } from '@/utils/format-date';
import {
  formatSchoolStage,
  getCorrectAdminSchoolOverviewPath,
} from '@/utils/location-utils';
import { format } from 'date-fns';
import { ChoiceCell } from './choice-cell';

type NurseryApplicationsListProps = {
  applicationsType: LocationType;
};

function NurseryApplicationsList(props: NurseryApplicationsListProps) {
  const { applicationsType } = props;
  const { pagination, sorting, filter, setPagination, setSorting } =
    useApplicationControl();
  const {
    isLoading,
    data: applications,
    error,
    isFetching,
  } = useApplications(pagination, sorting, filter, applicationsType);

  const adminOverviewPath = getCorrectAdminSchoolOverviewPath(applicationsType);

  const isApplicationOverviewScreen = Boolean(
    matchPath(adminOverviewPath, location.pathname)
  );

  const { data: ratios, isLoading: isRatiosLoading } = useRatios();

  const params = useParams<{ locationId?: string; schoolId?: string }>();
  const locationId = params.locationId || '';
  const schoolId = params.schoolId || '';

  const { isOpen, closeModal, openModal } = useModal(
    isApplicationOverviewScreen
  );
  const columnHelper = createColumnHelper<ApplicationListing>();
  const navigate = useNavigate();

  const columns = useMemo(
    () =>
      compact([
        columnHelper.accessor('childName', {
          id: 'name',
          cell: info => (
            <p className="block text-sm">
              <span className="flex items-center space-x-2 font-medium text-gray-900">
                <span>
                  {highlightQuery(info.row.original.childName, filter.search)}
                </span>

                <ApplicationListFlags application={info.row.original} />
              </span>
            </p>
          ),
          header: () => <span>Applicant</span>,
          enableSorting: true,
          // size: 358,
        }),

        columnHelper.accessor('dateOfBirth', {
          id: 'ratio',
          header: () => <span>DOB</span>,
          size: 154,
          cell: info => (
            <p>
              <span className="text-sm text-gray-600">
                {formatDateToShortDate(info.getValue())}
              </span>
            </p>
          ),
          enableSorting: false,
        }),
        applicationsType === 'ELC'
          ? columnHelper.accessor('intake', {
              id: 'intake',
              cell: info => (
                <>
                  <p className="flex items-center space-x-2 font-medium text-gray-900">
                    {info.row.original.intake || '-'}
                  </p>
                  {info.row.original.cohort && (
                    <p>{info.row.original.cohort}</p>
                  )}
                </>
              ),
              header: () => <span>Intake</span>,
              enableSorting: false,
              size: 100,
            })
          : columnHelper.accessor('yearStage', {
              id: 'yearStage',
              cell: info => {
                const stage = formatSchoolStage(info.getValue());
                const start = info.row.original.startDate.override;
                return (
                  <div className="flex flex-col">
                    <span className="font-medium text-gray-900">{stage}</span>
                    <span>{format(start, 'dd/MM/yyyy')}</span>
                  </div>
                );
              },
              header: () => <span>Stage/Start</span>,
            }),
        columnHelper.accessor('choices', {
          id: '1_choice',
          header: () => <span>1st</span>,
          size: 198,
          cell: info => {
            const choices = info.row.original.choices;

            if (choices.length < 1) {
              return <p className="text-sm text-gray-500">-</p>;
            }
            const actualChoice = choices[0];

            const idsToMatch = compact([
              actualChoice.location.id,
              actualChoice.splitPlacement?.location?.id.toString(),
            ]);

            if (idsToMatch.some(id => id === locationId)) {
              return <ChoiceTableCell choice={actualChoice} />;
            }

            if (idsToMatch.some(id => id === schoolId)) {
              return <ChoiceCell choice={actualChoice} />;
            }

            return <p className="text-sm text-gray-500">N/A</p>;
          },
        }),
        columnHelper.accessor('choices', {
          id: '2_choice',
          header: () => <span>2nd</span>,
          size: 198,
          cell: info => {
            const choices = info.row.original.choices;

            if (choices.length < 2) {
              return <p className="text-sm text-gray-500">-</p>;
            }
            const actualChoice = choices[1];

            const idsToMatch = compact([
              actualChoice.location.id,
              actualChoice.splitPlacement?.location?.id.toString(),
            ]);

            if (idsToMatch.some(id => id === locationId)) {
              return <ChoiceTableCell choice={actualChoice} />;
            }

            if (idsToMatch.some(id => id === schoolId)) {
              return <ChoiceCell choice={actualChoice} />;
            }

            return <p className="text-sm text-gray-500">N/A</p>;
          },
        }),
        columnHelper.accessor('choices', {
          id: '3_choice',
          header: () => <span>3rd</span>,
          size: 198,
          cell: info => {
            const choices = info.row.original.choices;

            if (choices.length < 3) {
              return <p className="text-sm text-gray-500">-</p>;
            }
            const actualChoice = choices[2];

            const idsToMatch = compact([
              actualChoice.location.id,
              actualChoice.splitPlacement?.location?.id.toString(),
            ]);

            if (idsToMatch.some(id => id === locationId)) {
              return <ChoiceTableCell choice={actualChoice} />;
            }

            if (idsToMatch.some(id => id === schoolId)) {
              return <ChoiceCell choice={actualChoice} />;
            }

            return <p className="text-sm text-gray-500">N/A</p>;
          },
        }),
        columnHelper.accessor('choices', {
          id: 'score',
          header: () => <span>Score</span>,
          size: 90,
          cell: info => (
            <p className="font-mono">
              {getScoreByLocation(info.row.original.choices, locationId)}
            </p>
          ),
        }),
        columnHelper.display({
          id: 'id',
          cell: info => (
            <button
              type="button"
              className="view-application-tooltip icon-link"
              onClick={() => {
                openModal();
                navigate(
                  generatePath(adminOverviewPath, {
                    id: info.row.original.id,
                    locationId: locationId,
                    schoolId: schoolId,
                  })
                );
              }}
            >
              <Eye className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true" />
              <span className="sr-only">View application</span>
            </button>
          ),
          size: 72,
        }),
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter.search, ratios, locationId]
  );

  return (
    <div>
      <ApplicationQuickViewDrawer
        applicationsType={applicationsType}
        open={isOpen}
        onClose={closeModal}
      />
      <Tooltip
        anchorSelect=".view-application-tooltip"
        content="View Application"
        place="top"
        className="tooltip"
      />

      <Table
        columns={columns}
        data={applications?.docs}
        isLoading={isLoading || isRatiosLoading}
        isFetching={isFetching}
        error={getErrorMessage(error)}
        paginationType="manual"
        onPaginationChange={setPagination}
        pagination={pagination}
        pageCount={applications?.totalPages}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Applications Found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by importing an xls.
            </p>
          </div>
        }
        sortingType="server"
        sorting={sorting}
        onSortingChange={setSorting}
        className="mt-3"
      />
    </div>
  );
}

export { NurseryApplicationsList };
