import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { YearStageSelect } from '@/components/year-stage-select';
import { useSchoolYear } from '@/context/school-year.context';
import { yupResolver } from '@hookform/resolvers/yup';
import { FileDownload03, Loading01 } from '@untitled-ui/icons-react';
import { Play } from '@untitled-ui/icons-react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { DenominationSelect } from '@/components/denomination-select';
import { MatrixReportTable } from './matrix-report-table';

const summaryReportRequestSchema = yup.object({
  yearStage: yup.string().required().label('Year Stage'),
  denomination: yup.string().required().label('Denomination'),
  schoolYear: yup.string().required().label('School Year'),
});

function MatrixReport() {
  const { schoolYear } = useSchoolYear();
  const form = useForm({
    context: {
      schoolYear,
    },
    resolver: yupResolver(summaryReportRequestSchema),
    defaultValues: {
      yearStage: 'primaryOne',
      schoolYear: '',
    },
  });

  //TODO: Remove this
  const isLoading = false;

  const submitHandler = (data: any) => {
    console.log(data);
  };

  const exportCSV = () => {
    console.log('Export to CSV');
  };

  return (
    <div>
      <PageTitle
        title="Matrix Report"
        description="Custom Report"
        variant="gray"
      />
      <div className="two-col-form mt-6 gap-6 py-6">
        <div className="flex flex-col">
          <h2 className="font-semibold text-gray-700">Report details</h2>
          <span className="text-gray-600">Configure the Matrix Report</span>
        </div>
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(submitHandler)}
            className="col-span-2"
          >
            <div className="grid gap-6 rounded-lg border border-solid border-gray-200 px-4 py-6">
              <div className="grid gap-6 sm:grid-cols-2">
                <div className="space-y-6">
                  <YearStageSelect disabled={form.formState.isSubmitting} />
                  <DenominationSelect disabled={form.formState.isSubmitting} />
                  <SchoolYearFilter
                    label="Select School Year"
                    className="shadow-sm"
                    disabled={form.formState.isSubmitting}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button className="btn btn-primary">
                  <div className="flex items-center gap-2">
                    {isLoading ? (
                      <Loading01
                        className="leading-icon animate-spin"
                        aria-hidden="true"
                      />
                    ) : (
                      <Play className="leading-icon" aria-hidden="true" />
                    )}
                    {isLoading ? 'Running Report' : 'Run Report'}
                  </div>
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      <div className="flex flex-col gap-6 bg-white pb-6">
        <div className="flex items-center justify-between pt-6">
          <div className="flex items-center gap-4">
            <h4 className="text-lg font-semibold text-gray-900">Output</h4>
          </div>
          <div className="flex items-center gap-2">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={exportCSV}
            >
              <div className="flex items-center gap-2">
                <FileDownload03 className="h-5 w-5" />
                Export to CSV
              </div>
            </button>
          </div>
        </div>

        <MatrixReportTable />
      </div>
    </div>
  );
}

export default MatrixReport;
