import { staffRouterPath } from '@/config/route-paths.config';
import {
  LocationAddress,
  LocationStatusUnion,
  LocationType,
  SchoolApplicationYearStage,
  SchoolLocation,
  SchoolLocationType,
  SchoolYear,
  SessionTiming,
} from '@admissions-support/types';
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  endOfMonth,
  format,
  getDay,
  isWithinInterval,
  lastDayOfMonth,
  lastDayOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { compact } from 'lodash-es';
import { generatePath } from 'react-router-dom';

function formatLocationAddress(address: LocationAddress) {
  const addressArray = [
    address.streetAddress,
    address.streetAddressTwo,
    address.city,
    address.postcode,
  ].filter(address => Boolean(address?.trim()));

  return addressArray.join(', ');
}

type MonthValue = {
  label: string;
  month: string;
  year: string;
  start: string;
  end: string;
};

function generateMonthObjects(schoolYear: SchoolYear): MonthValue[] {
  const result = eachMonthOfInterval({
    start: new Date(schoolYear.startDate),
    end: new Date(schoolYear.endDate),
  });
  return result.map(date => {
    const formattedMonth =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    return {
      label: format(date, 'yyyy LLLL'),
      month: formattedMonth,
      year: date.getFullYear().toString(),
      start: format(startOfMonth(date), 'yyyy-MM-dd'),
      end: format(endOfMonth(date), 'yyyy-MM-dd'),
    };
  });
}

type DayObject = {
  date: string;
  isCurrentMonth: boolean;
  events: never[];
  dayOfWeek: number;
  isInSession?: boolean;
};

function generateDaysInMonth(year: string, month: string): DayObject[] {
  const dayObjs: DayObject[] = [];

  const monthDays = eachDayOfInterval({
    start: startOfWeek(new Date(`${year}-${month}-01`), { weekStartsOn: 1 }),
    end: lastDayOfWeek(lastDayOfMonth(new Date(`${year}-${month}-01`)), {
      weekStartsOn: 1,
    }),
  });

  monthDays.forEach((day: Date) => {
    const date = format(day, 'yyyy-MM-dd');
    const isCurrentMonth = isWithinInterval(day, {
      start: startOfDay(new Date(`${year}-${month}-01`)),
      end: lastDayOfMonth(new Date(`${year}-${month}-01`)),
    });
    dayObjs.push({
      date: date,
      isCurrentMonth: isCurrentMonth,
      events: [],
      // Sundays return 0 with this function so we need to change that to 7 in order to get the correct number of objects
      dayOfWeek: getDay(new Date(date)) === 0 ? 7 : getDay(new Date(date)),
    });
  });

  return [...dayObjs];
}

/**
 * Converts session timing to an array of weekday numbers (1=Monday, 7=Sunday)
 */
function sessionDaysToWeekDays(sessionTiming?: SessionTiming): number[] {
  if (!sessionTiming) {
    return [];
  }
  const nameOfDays = Object.keys(sessionTiming).filter(
    nameOfDay => sessionTiming[nameOfDay as keyof SessionTiming]
  );

  const weekdayNumber = compact(
    nameOfDays.map(nameOfDay => {
      switch (nameOfDay) {
        case 'monday':
          return 1;
        case 'tuesday':
          return 2;
        case 'wednesday':
          return 3;
        case 'thursday':
          return 4;
        case 'friday':
          return 5;
        case 'saturday':
          return 6;
        case 'sunday':
          return 7;
        default:
          break;
      }
    })
  );

  return weekdayNumber;
}

const getTagType = (value: LocationStatusUnion) => {
  switch (value) {
    case 'ACTIVE':
      return 'success';
    case 'ARCHIVED':
      return 'error';
    case 'INACTIVE':
      return 'default';
    default:
      return 'default';
  }
};

const generateClassByCapacity = (capacity: number, allocated: number) => {
  if (allocated > capacity) {
    return 'rounded-md border border-red-200 bg-red-50 px-2 py-0.5 text-red-700';
  }

  if (allocated === capacity) {
    return 'rounded-md border border-yellow-200 bg-yellow-50 px-2 py-0.5 text-yellow-700';
  }

  return 'rounded-md border border-green-200 bg-green-50 px-2 py-0.5 text-green-700';
};

const getLocationNavigation = (id: string, hasExtraHourPermission: boolean) => [
  {
    name: 'Overview',
    url: generatePath(staffRouterPath.OVERVIEW_LOCATION, {
      locationId: id,
    }),
  },
  {
    name: 'Applications',
    url: generatePath(staffRouterPath.LOCATION_APPLICATIONS, {
      locationId: id,
    }),
  },
  {
    name: 'Capacity',
    url: generatePath(staffRouterPath.CAPACITY, {
      locationId: id,
    }),
  },
  {
    name: 'Resource',
    url: generatePath(staffRouterPath.RESOURCES, {
      locationId: id,
    }),
  },
  {
    name: 'Sessions',
    url: generatePath(staffRouterPath.SESSIONS, {
      locationId: id,
    }),
  },
  ...(hasExtraHourPermission
    ? [
        {
          name: 'Purchase Extra Hours',
          url: generatePath(
            staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_BASE,
            {
              locationId: id,
            }
          ),
          activePath: [
            generatePath(
              staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_CALENDAR,
              {
                locationId: id,
              }
            ),
            generatePath(
              staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_PRODUCTS,
              { locationId: id }
            ),
            generatePath(
              staffRouterPath.LOCATION_PURCHASE_EXTRA_HOURS_AVAILABILITY,
              { locationId: id }
            ),
          ],
        },
      ]
    : []),
];

const getSchoolLocationNavigation = (id: string) => [
  {
    name: 'Overview',
    url: generatePath(staffRouterPath.OVERVIEW_SCHOOL_LOCATION, {
      schoolId: id,
    }),
  },
  {
    name: 'Applications',
    url: generatePath(staffRouterPath.SCHOOL_LOCATION_APPLICATIONS, {
      schoolId: id,
    }),
  },
  {
    name: 'Stages',
    url: generatePath(staffRouterPath.SCHOOL_LOCATION_STAGES, {
      schoolId: id,
    }),
  },
];

const SCHOOL_STAGE_LABELS: Record<SchoolApplicationYearStage, string> = {
  primaryOne: 'Primary 1',
  primaryTwo: 'Primary 2',
  primaryThree: 'Primary 3',
  primaryFour: 'Primary 4',
  primaryFive: 'Primary 5',
  primarySix: 'Primary 6',
  primarySeven: 'Primary 7',
  secondaryOne: 'Secondary 1',
  secondaryTwo: 'Secondary 2',
  secondaryThree: 'Secondary 3',
  secondaryFour: 'Secondary 4',
  secondaryFive: 'Secondary 5',
  secondarySix: 'Secondary 6',
};

const formatSchoolStage = (
  stage?: SchoolApplicationYearStage
): string | undefined => {
  if (!stage) {
    return;
  }
  return SCHOOL_STAGE_LABELS[stage] ?? undefined;
};

const getCorrectAdminSchoolOverviewPath = (type: LocationType) => {
  switch (type) {
    case 'ELC':
      return staffRouterPath.LOCATION_APPLICATION_OVERVIEW;
    case 'PRIMARY':
    case 'SECONDARY':
      return staffRouterPath.SCHOOL_LOCATION_APPLICATION_OVERVIEW;
    default:
      return staffRouterPath.LOCATION_APPLICATION_OVERVIEW;
  }
};

const getSchoolProviderType = (type?: SchoolLocationType) => {
  switch (type) {
    case 'PRIMARY':
    case 'SECONDARY':
      return 'DENOMINATIONAL';
    case 'PRIMARY_NON_DENOMINATIONAL':
    case 'SECONDARY_NON_DENOMINATIONAL':
      return 'NON_DENOMINATIONAL';
    default:
      return undefined;
  }
};

const isSchoolLocation = (
  location: Record<string, any>
): location is SchoolLocation => {
  return Boolean('stages' in location);
};

export {
  formatLocationAddress,
  generateClassByCapacity,
  generateDaysInMonth,
  generateMonthObjects,
  getTagType,
  sessionDaysToWeekDays,
  getLocationNavigation,
  getSchoolLocationNavigation,
  formatSchoolStage,
  getCorrectAdminSchoolOverviewPath,
  SCHOOL_STAGE_LABELS,
  getSchoolProviderType,
  isSchoolLocation,
  type DayObject,
  type MonthValue,
};
