import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { progressApplicationsSchema } from './bulk-action.schema';
import {
  ProgressApplicationsDto,
  Cohort,
  SchoolYear,
  LocationType,
} from '@admissions-support/types';
import { Alert } from '@/components/alert';
import { CustomSelect } from '@/components/custom-select';
import { useProgressApplicationsMutation } from '@/hooks/use-progress-applications-mutation';
import toast from 'react-hot-toast';
import { compact } from 'lodash-es';

type BulkProgressApplicationsFormProps = {
  applicantIds: string[];
  onClose: () => void;
  schoolYears: SchoolYear[];
  cohorts: Cohort[];
  applicationsType: LocationType;
};

function BulkProgressApplicationsForm(
  props: BulkProgressApplicationsFormProps
) {
  const { applicantIds, onClose, schoolYears, cohorts, applicationsType } =
    props;

  const schoolYearsOptions = schoolYears.map(schoolYear => {
    return {
      key: schoolYear.id,
      value: schoolYear.name,
    };
  });

  const cohortsOptions = cohorts.map(cohort => {
    return {
      key: cohort.id,
      value: cohort.name,
    };
  });

  const { mutateAsync: progressionApplications, isPending } =
    useProgressApplicationsMutation();

  const form = useForm({
    defaultValues: {
      applicantIds,
      schoolYear: '',
      stage: '',
    },
    resolver: yupResolver(progressApplicationsSchema),
  });

  const setSelectValue = (
    fieldName: 'schoolYear' | 'stage',
    newValue: string
  ) => {
    form.setValue(fieldName, newValue);
  };

  const cancel = () => {
    form.reset();
    onClose();
  };

  const submit = async () => {
    const formValues = form.getValues();
    const payload: ProgressApplicationsDto = {
      applicationIds: formValues.applicantIds.filter(Boolean) as string[],
      applicationsType: applicationsType,
      cohortId: formValues.stage,
      schoolYearId: formValues.schoolYear,
    };

    const progressionApplicationPromise = progressionApplications(payload);
    await toast.promise(progressionApplicationPromise, {
      loading: 'Progressing applications...',
      success: result => {
        const nbSuccess = Object.keys(result.successes).length;
        const nbErrors = Object.keys(result.errors).length;
        const successMessage =
          nbSuccess > 0 &&
          `${nbSuccess} application(s) were successfully progressed!`;
        const errorMessage =
          nbErrors > 0 && `${nbErrors} application(s) could not be progressed.`;

        return compact([successMessage, errorMessage]).join(' ');
      },
      error: err => {
        return err.message || 'Failed to progress applications!';
      },
    });

    onClose();
  };

  return (
    <FormProvider {...form}>
      <form
        id="bulk-progress-applications-form"
        className="flex flex-col gap-6"
      >
        <Alert
          type="warning"
          text="Applications will be marked as placed, consuming resources, however parents will not be notified."
          title="Attention"
        />
        <CustomSelect
          isDisabled={isPending}
          name="schoolYear"
          label="School Year to Progress to"
          options={schoolYearsOptions}
          value=""
          onChange={newVal => setSelectValue('schoolYear', newVal)}
        />
        <CustomSelect
          isDisabled={isPending}
          name="stage"
          label="Stage to Progress to"
          options={cohortsOptions}
          value=""
          onChange={newVal => setSelectValue('stage', newVal)}
        />
        <div className="mt-6 grid grid-cols-1 gap-3 sm:grid-cols-2">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={cancel}
            disabled={isPending}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={submit}
            disabled={isPending}
          >
            Send Message
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

export { BulkProgressApplicationsForm };
