import { applicationKey, logKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { ChoicesProvider } from '@/context/choices.context';
import { useCreateChoiceMutation } from '@/hooks/create-hooks/use-create-choice-mutation';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { useSessionTypes } from '@/hooks/query-hooks/use-session-types';
import { useWeekModels } from '@/hooks/query-hooks/use-week-models';
import { useUpdateChoiceMutation } from '@/hooks/update-hooks/use-update-choice-mutation';
import { useModal } from '@/hooks/use-modal';
import { queryClient } from '@/libs/react-query';
import { Choice } from '@admissions-support/types';
import { Plus } from '@untitled-ui/icons-react';
import { maxBy, sortBy } from 'lodash-es';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { LocationChoice } from './location-choice';
import { ChoiceModal } from './placement-preferences/choices/choice-modal';

function Choices(props: { disabled?: boolean; applicationId?: string }) {
  const { disabled, applicationId: appId } = props;
  const params = useParams<{ id: string }>();
  const applicationId = params.id || appId || '';
  const [choiceToEdit, setChoiceToEdit] = useState<Choice | undefined>(
    undefined
  );
  const {
    data: application,
    error: applicationError,
    isSuccess: isApplicationSuccess,
  } = useApplication(applicationId, {
    enabled: Boolean(applicationId),
  });

  const { data: locationsData, error: locationError } = useLocations(
    undefined,
    {
      select: locations =>
        //hide archived/inactive locations
        locations.filter(location => location.status === 'ACTIVE'),
    }
  );

  const { data: sessionTypesData } = useSessionTypes();
  const { data: modelsData } = useWeekModels();

  const { mutateAsync: createChoice, isPending: isChoiceCreating } =
    useCreateChoiceMutation(applicationId, {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: applicationKey.single(applicationId),
        });
      },
    });
  const { mutateAsync: updateChoice, isPending: isChoiceUpdating } =
    useUpdateChoiceMutation({
      onSuccess: updatedApplication => {
        queryClient.invalidateQueries({
          queryKey: ['application'],
        });
        queryClient.invalidateQueries({
          queryKey: ['application-list'],
        });
        queryClient.invalidateQueries({
          queryKey: logKey.single(updatedApplication.id),
        });
      },
    });
  const {
    isOpen: isEditModalOpen,
    openModal: openEditModal,
    closeModal: closeEditModal,
  } = useModal();
  const {
    isOpen: isAddModalOpen,
    openModal: openAddModal,
    closeModal: closeAddModal,
  } = useModal();

  const choices = application?.choices || [];

  const maximumChoiceNumber = maxBy(choices, 'nbPreference')?.nbPreference;
  const nextChoiceNumber = (maximumChoiceNumber || 0) + 1;

  const orderedChoices = sortBy(choices, ['nbPreference']);
  if (applicationError || locationError || !isApplicationSuccess) {
    return <Navigate to={staffRouterPath.APPLICATIONS} />;
  }

  const schoolYearId = application.schoolYear.id.toString();

  const handleCloseEditModal = () => {
    setChoiceToEdit(undefined);
    closeEditModal();
  };

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Your Placement Preferences
        </p>
        <p className="text-md leading-6 text-gray-600">
          You can select three attendance patterns, in order of preference.
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        {application.choices.length < 3 && (
          <button
            type="button"
            className="btn btn-secondary flex items-center"
            onClick={openAddModal}
            disabled={disabled}
          >
            <Plus className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true" />
            Add Placement Preference
          </button>
        )}

        {orderedChoices.map(choice => (
          <div key={choice.id} className="group relative">
            <button
              className="btn btn-secondary btn-sm absolute right-4 top-4 ml-auto hidden self-start group-hover:block"
              onClick={() => {
                setChoiceToEdit(choice as Choice);
                openEditModal();
              }}
              disabled={disabled}
              type="button"
            >
              Edit
            </button>

            <div className="space-y-3">
              <LocationChoice
                schoolYearId={schoolYearId}
                choice={choice as Choice}
              />
            </div>
          </div>
        ))}
      </div>

      {choiceToEdit && (
        <ChoicesProvider schoolYearId={schoolYearId} choice={choiceToEdit}>
          <ChoiceModal
            onClose={handleCloseEditModal}
            open={isEditModalOpen}
            schoolYearId={schoolYearId}
            onSubmit={data =>
              updateChoice({ applicationId, choiceId: choiceToEdit.id, data })
            }
            initialData={choiceToEdit}
            isMutating={isChoiceUpdating}
            locations={locationsData || []}
            sessionTypes={sessionTypesData || []}
            models={modelsData || []}
          />
        </ChoicesProvider>
      )}

      {isAddModalOpen ? (
        <ChoicesProvider schoolYearId={schoolYearId}>
          <ChoiceModal
            onClose={closeAddModal}
            open={isAddModalOpen}
            schoolYearId={schoolYearId}
            onSubmit={createChoice}
            nbPreference={nextChoiceNumber}
            isMutating={isChoiceCreating}
            locations={locationsData || []}
            sessionTypes={sessionTypesData || []}
            models={modelsData || []}
          />
        </ChoicesProvider>
      ) : null}
    </>
  );
}

export { Choices };
