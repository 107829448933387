import { Table } from '@/components/table';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import { CapacityTableActionsCell } from './capacity-planning-demand/capacity-table-cells';

type CatchmentPupilsTableRow = {
  sameDenominationSibling: number;
  noSibling: number;
  crossDenominationSibling: number;
  noSibling2: number;
  forOtherPrimaries: number;
};

function CatchmentPupilsTable() {
  const tableData: CatchmentPupilsTableRow[] = [
    {
      sameDenominationSibling: 100,
      noSibling: 100,
      crossDenominationSibling: 100,
      noSibling2: 100,
      forOtherPrimaries: 100,
    },
    {
      sameDenominationSibling: 100,
      noSibling: 100,
      crossDenominationSibling: 100,
      noSibling2: 100,
      forOtherPrimaries: 100,
    },
  ];
  const columnHelper = createColumnHelper<CatchmentPupilsTableRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('sameDenominationSibling', {
        header: 'Same Denomination Sibling',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('noSibling', {
        header: 'No Sibling',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('crossDenominationSibling', {
        header: 'Cross Denomination Sibling',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('noSibling2', {
        header: 'No Sibling',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('forOtherPrimaries', {
        header: 'For Other Primaries',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
    ],
    [columnHelper]
  );

  return <Table columns={columns} data={tableData} className="mt-0" />;
}

type NonCatchmentPupilsTableRow = {
  sameDenominationSibling: number;
  noSibling: number;
  crossDenominationSibling: number;
  noSibling2: number;
};

function NonCatchmentPupilsTable() {
  const tableData: NonCatchmentPupilsTableRow[] = [
    {
      sameDenominationSibling: 100,
      noSibling: 100,
      crossDenominationSibling: 100,
      noSibling2: 100,
    },
    {
      sameDenominationSibling: 100,
      noSibling: 100,
      crossDenominationSibling: 100,
      noSibling2: 100,
    },
  ];
  const columnHelper = createColumnHelper<NonCatchmentPupilsTableRow>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('sameDenominationSibling', {
        header: 'Same Denomination Sibling',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('noSibling', {
        header: 'No Sibling',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('crossDenominationSibling', {
        header: 'Cross Denomination Sibling',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('noSibling2', {
        header: 'No Sibling',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
    ],
    [columnHelper]
  );
  return <Table columns={columns} data={tableData} className="mt-0" />;
}

function renderExpandedRow(
  report: SummaryReportTableRow,
  row: Row<SummaryReportTableRow>
) {
  return (
    <tr className="flex w-full bg-gray-50">
      <td
        colSpan={row.getVisibleCells().length}
        className="w-full p-0"
        style={{ padding: 0 }}
      >
        <div className="flex w-full flex-col gap-7 p-6">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-gray-900">
                Applications on behalf of catchment pupils
              </span>
              <CatchmentPupilsTable />
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-sm font-medium text-gray-900">
                Non Catchment
              </span>
              <NonCatchmentPupilsTable />
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}

type SummaryReportTableRow = {
  id: string;
  schoolName: string;
  demand: number;
  catchmentApplications: number;
  nonCatchmentApplications: number;
  intakeLimits: number;
  surplusOrDeficit: number;
};

function SummaryReportTable() {
  const tableData: SummaryReportTableRow[] = [
    {
      id: 'asdiodsoidsioads',
      schoolName: 'School 1',
      demand: 100,
      catchmentApplications: 100,
      nonCatchmentApplications: 100,
      intakeLimits: 100,
      surplusOrDeficit: 100,
    },
    {
      id: 'asdiodsoidsioads',
      schoolName: 'School 2',
      demand: 100,
      catchmentApplications: 100,
      nonCatchmentApplications: 100,
      intakeLimits: 100,
      surplusOrDeficit: 100,
    },
  ];
  const columnHelper = createColumnHelper<SummaryReportTableRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('schoolName', {
        header: 'School Name',
        cell: info => {
          return (
            <div>
              <span className="font-medium text-gray-900">
                {info.getValue()}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor('demand', {
        header: 'Demand',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('catchmentApplications', {
        header: 'Catchment Applications',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('nonCatchmentApplications', {
        header: 'Non-Catchment Applications',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('intakeLimits', {
        header: 'Intake Limits',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('surplusOrDeficit', {
        header: 'Surplus or Deficit',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: ({ row: { toggleExpanded, getIsExpanded } }) => {
          return (
            <CapacityTableActionsCell
              isExpanded={getIsExpanded()}
              expandRow={toggleExpanded}
            />
          );
        },
      }),
    ],
    [columnHelper]
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      paginationType="auto"
      isFetching={false}
      renderExpandedRow={row => renderExpandedRow(tableData[0], row)}
      className="mt-0"
    />
  );
}

export { SummaryReportTable };
