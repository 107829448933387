import { ChangeEvent, useState } from 'react';
import { SearchSm } from '@untitled-ui/icons-react';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { FilterItem } from '@/components/filter-item';
import { CheckboxBase } from '@/components/form/common/checkbox';
import { TextInputBase } from '@/components/form/common/text-input';
import { Tag } from '@/components/tag';
import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';

type SelectedLocationsProps = {
  selectedLocationIds: string[];
};

function SelectedLocations({ selectedLocationIds }: SelectedLocationsProps) {
  const { data: locations = [] } = useLocations();
  const selectedLocations = locations.filter(location =>
    selectedLocationIds.includes(location.id)
  );

  if (!selectedLocations.length) {
    return null;
  }

  const locationName = selectedLocations[0]?.name;

  return (
    <Tag key={locationName} type="outline" className="px-1 py-0 text-xs">
      {locationName}
    </Tag>
  );
}

function LocationSearchFilter() {
  const { filter, setFilter } = useApplicationControl();
  const [search, setSearch] = useState('');
  const { data: locationsData } = useLocations();
  const locations =
    locationsData
      ?.filter(location =>
        location.name.toLowerCase().includes(search.toLowerCase())
      )
      .map(location => ({
        label: location.name,
        value: location.id,
      })) || [];

  const selectedLocations = locations
    .filter(location => filter.locations.includes(location.value))
    .sort(
      (a, b) =>
        filter.locations.indexOf(a.value) - filter.locations.indexOf(b.value)
    )
    .map(location => location.value);

  const reset = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      locations: defaultApplicationControl.filter.locations,
    }));
    setSearch('');
  };

  const resetSearch = () => {
    setSearch('');
  };

  const handleLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedLocation = event.target.name;

    const index = filter.locations.indexOf(selectedLocation);
    if (index === -1) {
      setFilter(prevFilter => ({
        ...prevFilter,
        locations: [...prevFilter.locations, selectedLocation],
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        locations: prevFilter.locations.filter(
          location => location !== selectedLocation
        ),
        isSiblingAtLocation:
          prevFilter.locations.length === 1
            ? false
            : prevFilter.isSiblingAtLocation,
      }));
    }
  };

  const handleSelectAllLocations = () => {
    if (filter.locations.length === locations.length) {
      setFilter(prevFilter => ({
        ...prevFilter,
        locations: defaultApplicationControl.filter.locations,
        isSiblingAtLocation: false,
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        locations: locations.map(s => s.value),
      }));
    }
  };

  const isSomeSelected = locations.some(location =>
    filter.locations.includes(location.value)
  );

  return (
    <div className="relative inline-block text-left">
      <FilterItem
        label="Location"
        counter={selectedLocations.length}
        reset={reset}
        onClose={resetSearch}
        renderSelectedItems={
          <SelectedLocations selectedLocationIds={selectedLocations} />
        }
      >
        <div className="mb-4">
          <TextInputBase
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search Locations"
            LeadingIcon={<SearchSm className="h-4 w-4" />}
          />
        </div>
        <div className="mt-2 max-h-96 min-w-[300px] space-y-2">
          {locations.map(location => {
            return (
              <CheckboxBase
                key={location.value}
                name={location.value}
                label={location.label}
                onChange={handleLocationChange}
                checked={
                  filter.locations.findIndex(c => c === location.value) >= 0
                }
              />
            );
          })}
          <hr className="!my-4" />
          <CheckboxBase
            name="selectAllLocations"
            label="Select All"
            onChange={handleSelectAllLocations}
            checked={
              filter.locations.length === locations.length &&
              locations.length !== 0
            }
            indeterminate={isSomeSelected}
          />
        </div>
      </FilterItem>
    </div>
  );
}

export { LocationSearchFilter };
