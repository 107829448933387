import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  CloneSchoolYearDto,
  Cohort,
  CreateCohortDto,
  CreateInvitationDto,
  CreateModelsDto,
  CreateRatioDto,
  CreateSchoolYearDto,
  CreateUserGroupDto,
  IntakeWithSchoolYear,
  Invitation,
  Model,
  Organisation,
  OrganisationPublic,
  Ratio,
  SchoolYear,
  SetCatchmentAreasJsonDto,
  UpdateCohortDto,
  UpdateModelsDto,
  UpdateOrganisationDto,
  UpdateRatioDto,
  UpdateSchoolYearDto,
  UpdateUserGroupDto,
  UserGroup,
  WhichIntakeDto,
} from '@admissions-support/types';
import { AxiosResponse } from 'axios';

const getPublicOrganisationByUrl = async (
  url: string
): Promise<OrganisationPublic> => {
  const publicOrganisation = await axiosClient.get<
    HttpError,
    AxiosResponse<OrganisationPublic>
  >(`/organisations/metadata?name=${url}`, { disableToast: true });

  return publicOrganisation.data;
};

const listUserGroups = async (): Promise<UserGroup[]> => {
  const response = await axiosClient.get<UserGroup[]>(
    `/organisations/user-groups`
  );

  return response.data;
};

const createInvitation = async (
  data: CreateInvitationDto
): Promise<Invitation> => {
  const response = await axiosClient.post<
    HttpError,
    Invitation,
    CreateInvitationDto
  >(`/invitations`, data);

  return response;
};

const resendInvitation = async (id: string): Promise<Invitation> => {
  const response = await axiosClient.post<HttpError, Invitation>(
    `/invitations/${id}/actions/resend`
  );

  return response;
};

async function createUserGroup(data: CreateUserGroupDto) {
  const response = await axiosClient.post<
    HttpError,
    Organisation,
    CreateUserGroupDto
  >(`/organisations/user-groups`, data);

  return response;
}

async function getUserGroup(id: string): Promise<UserGroup> {
  const response = await axiosClient.get<UserGroup>(
    `/organisations/user-groups/${id}`
  );

  return response.data;
}

async function updateUserGroup({
  id,
  data,
}: {
  id: string;
  data: UpdateUserGroupDto;
}) {
  const response = await axiosClient.patch<
    HttpError,
    UserGroup,
    UpdateUserGroupDto
  >(`/organisations/user-groups/${id}`, data);

  return response;
}

async function deleteUserGroup(id: string) {
  await axiosClient.delete(`organisations/user-groups/${id}`);
}

async function listWeekModels(): Promise<Model[]> {
  const response = await axiosClient.get<Model[]>(`/organisations/models`);

  return response.data;
}

async function getWeekModel(id: string): Promise<Model> {
  const response = await axiosClient.get<Model>(`/organisations/models/${id}`);

  return response.data;
}

async function createWeekModel(data: CreateModelsDto) {
  const response = await axiosClient.post<
    HttpError,
    Organisation,
    CreateModelsDto
  >(`/organisations/models`, data);

  return response;
}

async function updateWeekModel({
  id,
  data,
}: {
  id: string;
  data: UpdateModelsDto;
}) {
  const response = await axiosClient.patch<
    HttpError,
    Organisation,
    UpdateModelsDto
  >(`/organisations/models/${id}`, data);

  return response;
}

async function deleteWeekModel(id: string) {
  await axiosClient.delete(`/organisations/models/${id}`);
}

async function listRatios(): Promise<Ratio[]> {
  const response = await axiosClient.get<Ratio[]>(`/organisations/ratios`);

  return response.data;
}
async function listTerms(): Promise<SchoolYear[]> {
  const response = await axiosClient.get<SchoolYear[]>(
    `/organisations/school-years`
  );

  return response.data;
}

async function getRatio(id: string): Promise<Ratio> {
  const response = await axiosClient.get<Ratio>(`/organisations/ratios/${id}`);

  return response.data;
}

async function getTerm(id: string): Promise<SchoolYear> {
  const response = await axiosClient.get<SchoolYear>(
    `/organisations/school-years/${id}`
  );

  return response.data;
}

async function createRatio(data: CreateRatioDto) {
  const response = await axiosClient.post<
    HttpError,
    Organisation,
    CreateRatioDto
  >(`/organisations/ratios`, data);
  return response;
}

async function updateTerm({
  id,
  data,
}: {
  id: string;
  data: UpdateSchoolYearDto;
}) {
  const response = await axiosClient.patch<
    HttpError,
    Organisation,
    UpdateSchoolYearDto
  >(`/organisations/school-years/${id}`, data);

  return response;
}

async function updateRatio({ id, data }: { id: string; data: UpdateRatioDto }) {
  const response = await axiosClient.patch<
    HttpError,
    Organisation,
    UpdateRatioDto
  >(`/organisations/ratios/${id}`, data);
  return response;
}

async function createTerm(data: CreateSchoolYearDto | CloneSchoolYearDto) {
  const response = await axiosClient.post<
    HttpError,
    Organisation,
    CreateSchoolYearDto | CloneSchoolYearDto
  >(`/organisations/school-years`, data);

  return response;
}

async function deleteRatio(id: string) {
  await axiosClient.delete(`/organisations/ratios/${id}`);
}

async function deleteTerm(id: string) {
  await axiosClient.delete(`/organisations/school-years/${id}`);
}

async function listCohorts(): Promise<Cohort[]> {
  const response = await axiosClient.get<Cohort[]>(`/organisations/cohorts`);

  return response.data;
}

async function updateOrganisation({
  id,
  data,
}: {
  id: string;
  data: UpdateOrganisationDto;
}) {
  const response = await axiosClient.patch<
    HttpError,
    Organisation,
    UpdateOrganisationDto
  >(`/organisations/${id}`, data);

  return response;
}

async function getCohort(id: string): Promise<Cohort> {
  const response = await axiosClient.get<Cohort>(
    `/organisations/cohorts/${id}`
  );

  return response.data;
}

async function createCohort(data: CreateCohortDto) {
  const response = await axiosClient.post<HttpError, Cohort, CreateCohortDto>(
    `/organisations/cohorts`,
    data
  );
  return response;
}

async function updateCohort({
  id,
  data,
}: {
  id: string;
  data: UpdateCohortDto;
}) {
  const response = await axiosClient.patch<HttpError, Cohort, UpdateCohortDto>(
    `/organisations/cohorts/${id}`,
    data
  );

  return response;
}

async function deleteCohort(id: string) {
  await axiosClient.delete(`/organisations/cohorts/${id}`);
}

async function getEligibility(
  data: WhichIntakeDto
): Promise<IntakeWithSchoolYear> {
  const response = await axiosClient.get<IntakeWithSchoolYear>(
    `/organisations/school-years/which-intake?birthday=${data.birthday}&schoolYearId=${data.schoolYearId}`,
    { disableErrorToast: true }
  );

  return response.data;
}

async function addCatchmentArea(dto: SetCatchmentAreasJsonDto): Promise<void> {
  const response = await axiosClient.patch<void>(
    `/organisations/update-catchment-area`,
    dto
  );

  return response.data;
}

export const organisationService = Object.freeze({
  getPublicOrganisationByUrl,
  addCatchmentArea,
  listUserGroups,
  createInvitation,
  resendInvitation,
  createUserGroup,
  getUserGroup,
  updateUserGroup,
  deleteUserGroup,
  listWeekModels,
  getWeekModel,
  createWeekModel,
  updateWeekModel,
  deleteWeekModel,
  listRatios,
  getRatio,
  updateRatio,
  createRatio,
  deleteRatio,
  listTerms,
  getTerm,
  updateTerm,
  createTerm,
  deleteTerm,
  listCohorts,
  updateOrganisation,
  getCohort,
  createCohort,
  updateCohort,
  deleteCohort,
  getEligibility,
});
