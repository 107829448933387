import { bulkMessageSchema } from '@/components/application/bulk-action/bulk-action.schema';
import { CustomSelect } from '@/components/custom-select';
import { useBulkEmailApplicantsMutation } from '@/hooks/use-bulk-message-applications-mutation';
import {
  BulkEmailApplicantsDto,
  EmailTemplateEnum,
  LocationType,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

type BulkMessageApplicantsFormProps = {
  applicantIds: string[];
  onClose: () => void;
  applicationsType: LocationType;
};

const mockEmailTemplateValues: { key: string; value: string }[] = [
  {
    key: 'AskParentToTakeOverApplication',
    value: 'Invite parent to application',
  },
  {
    key: 'NotifiedAboutPlacement',
    value: 'Notify about placement',
  },
  {
    key: 'NotifiedAboutRefusal',
    value: 'Notify about refusal',
  },
  {
    key: 'SendAccountStatement',
    value: 'Send account statement',
  },
];

export function BulkMessageApplicantsForm({
  applicantIds,
  onClose,
  applicationsType,
}: BulkMessageApplicantsFormProps) {
  const form = useForm({
    defaultValues: {
      applicantIds: applicantIds,
      emailTemplateId: '',
    },
    resolver: yupResolver(bulkMessageSchema),
  });

  const { mutateAsync: sendBulkEmail } = useBulkEmailApplicantsMutation();

  const submit = async () => {
    const formValues = form.getValues();
    const payload: BulkEmailApplicantsDto = {
      applicationIds: formValues.applicantIds.filter(Boolean) as string[],
      emailTemplateId: formValues.emailTemplateId as EmailTemplateEnum,
      applicationsType,
    };

    const sendEmailPromise = sendBulkEmail(payload);
    await toast.promise(sendEmailPromise, {
      loading: 'Sending emails...',
      success: 'Emails sent successfully',
      error: 'Failed to send emails',
    });

    onClose();
  };

  const cancel = () => {
    form.reset();
    onClose();
  };

  const setEmailTemplate = (id: string) => {
    form.setValue('emailTemplateId', id);
  };

  return (
    <FormProvider {...form}>
      <form id="bulk-message-applicants-form" className="flex flex-col gap-6">
        <CustomSelect
          name="emailTemplate"
          label="Select Email Template"
          options={mockEmailTemplateValues}
          value=""
          onChange={setEmailTemplate}
        />
        <div className="mt-6 grid grid-cols-1 gap-3 sm:grid-cols-2">
          <button type="button" className="btn btn-secondary" onClick={cancel}>
            Cancel
          </button>
          <button type="button" className="btn btn-primary" onClick={submit}>
            Send Message
          </button>
        </div>
      </form>
    </FormProvider>
  );
}
