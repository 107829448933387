import { Disclosure } from '@/components/disclosure';
import { useListSchoolLocations } from '@/hooks/query-hooks/use-list-school-locations';
import { useFormContext } from 'react-hook-form';
import { Tag } from '../tag';
import { SchoolLocationType } from '@admissions-support/types';
import { CreatableSelect } from '../form/common/creatable-select';
import { InferType } from 'yup';
import { schoolPlacementPreferencesSchema } from './placement-preferences/school-placement-preferences.form';
import { TextInput } from '../form/common/text-input';
import { PermissionGuard } from '../auth/permission-guard';
import { Permission } from '@/types/auth';

const schoolTypeToText: Record<SchoolLocationType, string> = {
  PRIMARY: 'Denominational',
  PRIMARY_NON_DENOMINATIONAL: 'Non-Denominational',
  SECONDARY: 'Denominational',
  SECONDARY_NON_DENOMINATIONAL: 'Non-Denominational',
};

const schoolTypeToTagType: Record<SchoolLocationType, 'purple' | 'warning'> = {
  PRIMARY: 'warning',
  PRIMARY_NON_DENOMINATIONAL: 'purple',
  SECONDARY: 'warning',
  SECONDARY_NON_DENOMINATIONAL: 'purple',
};

type ValueContainerProps = {
  label: string;
  value: string;
  type: SchoolLocationType;
};

function GroupSelectValueContainer({ label, type }: ValueContainerProps) {
  return (
    <div className="flex items-center justify-between">
      <span>{label}</span>
      {type ? (
        <Tag type={schoolTypeToTagType[type]}>{schoolTypeToText[type]}</Tag>
      ) : null}
    </div>
  );
}

type PreferenceItemProps = {
  nbPreference: number; // 1, 2, 3
  schoolType: 'PRIMARY' | 'SECONDARY';
};

const preferenceTitles = ['First Choice', 'Second Choice', 'Third Choice'];

function PreferenceItem(props: PreferenceItemProps) {
  const { nbPreference, schoolType } = props;

  const { data: schoolLocations, isLoading } = useListSchoolLocations();

  const options =
    schoolLocations
      ?.filter(location => location.type.includes(schoolType))
      .map(location => ({
        label: location.name,
        value: location.id,
        type: location.type,
      })) || [];

  const index = nbPreference - 1;
  const currentFieldName = `choices[${index}]`;

  const form =
    useFormContext<InferType<typeof schoolPlacementPreferencesSchema>>();
  const values = form.watch();

  const alreadySelected = values?.choices
    .filter(v => !!v?.value)
    .map(({ value }) => value);

  const availableOptions =
    options?.filter(option => !alreadySelected.includes(option.value)) || [];

  const isSelectDisabled =
    isLoading || (availableOptions.length === 0 && !values.choices[index]);

  return (
    <div className="rounded-lg border border-gray-200 px-6">
      <Disclosure
        title={preferenceTitles[index]}
        content={
          <div className="flex flex-col gap-4 rounded-lg border border-gray-200 bg-gray-25 p-6">
            <div className="flex items-center justify-between">
              <span className="text-md font-medium leading-7 text-gray-900">
                Select Establishment
              </span>
            </div>
            <CreatableSelect
              name={currentFieldName}
              label="Establishment"
              options={availableOptions}
              isDisabled={isSelectDisabled}
              formatOptionLabel={({ value, label }) => (
                <GroupSelectValueContainer
                  label={label}
                  value={value}
                  type={
                    options.find(option => option.value === value)
                      ?.type as SchoolLocationType
                  }
                />
              )}
              placeholder="Select..."
            />

            <PermissionGuard
              requiredPermissions={[Permission['application:update']]}
            >
              <TextInput
                name={`choices[${index}].distanceToLocation`}
                label="Distance to location"
                helperText="The distance to the location in meters"
                placeholder="Enter distance to location"
                type="number"
                min={0}
                disabled={!values.choices[index]?.value}
              />
            </PermissionGuard>
          </div>
        }
        defaultOpen={index === 0 || !!values.choices[index]?.value}
      />
    </div>
  );
}

type PrimarySecondaryPlacementPreferencesProps = {
  schoolType: 'PRIMARY' | 'SECONDARY';
};

function PrimarySecondaryPlacementPreferences(
  props: PrimarySecondaryPlacementPreferencesProps
) {
  const { schoolType } = props;

  return (
    <div className="flex flex-col gap-6">
      <div>
        <span className="text-md font-medium leading-7 text-gray-900">
          Your Placement Preferences
        </span>
        <p className="leading-6 text-gray-600">
          You can select three attendance patterns, in order of preference.
        </p>
      </div>
      <div className="flex flex-col gap-6">
        <PreferenceItem nbPreference={1} schoolType={schoolType} />
        <PreferenceItem nbPreference={2} schoolType={schoolType} />
        <PreferenceItem nbPreference={3} schoolType={schoolType} />
      </div>
    </div>
  );
}

export default PrimarySecondaryPlacementPreferences;
