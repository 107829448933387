import { Table } from '@/components/table';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import { CapacityTableActionsCell } from './capacity-planning-demand/capacity-table-cells';

type MatrixSubTableRow = {
  schoolName: string;
  requests: number;
  isBold?: boolean;
};

function MatrixSubTable() {
  const tableData: MatrixSubTableRow[] = [
    {
      schoolName: 'School 1',
      requests: 100,
    },
    {
      schoolName: 'School 2',
      requests: 100,
    },
    {
      schoolName: 'Total Requests In',
      requests: 200,
      isBold: true,
    },
  ];
  const columnHelper = createColumnHelper<MatrixSubTableRow>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('schoolName', {
        header: 'Catchment School',
        cell: info => {
          return (
            <div className={info.row.original.isBold ? 'font-medium' : ''}>
              {info.getValue()}
            </div>
          );
        },
      }),
      columnHelper.accessor('requests', {
        header: 'Requests',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
    ],
    [columnHelper]
  );
  return <Table columns={columns} data={tableData} className="mt-0" />;
}

function renderExpandedRow(
  report: MatrixReportTableRow,
  row: Row<MatrixReportTableRow>
) {
  return (
    <tr className="flex w-full bg-gray-50">
      <td
        colSpan={row.getVisibleCells().length}
        className="w-full p-0"
        style={{ padding: 0 }}
      >
        <div className="flex w-full flex-col gap-7 p-6">
          <div className="flex flex-col gap-6">
            <MatrixSubTable />
          </div>
        </div>
      </td>
    </tr>
  );
}

type MatrixReportTableRow = {
  id: string;
  schoolName: string;
  requestsIn: number;
  catchment: number;
  nonCatchment: number;
};

function MatrixReportTable() {
  const tableData: MatrixReportTableRow[] = [
    {
      id: 'asdiodsoidsioads',
      schoolName: 'School 1',
      requestsIn: 100,
      catchment: 100,
      nonCatchment: 100,
    },
    {
      id: 'asdiodsoidsioads',
      schoolName: 'School 2',
      requestsIn: 100,
      catchment: 100,
      nonCatchment: 100,
    },
  ];
  const columnHelper = createColumnHelper<MatrixReportTableRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('schoolName', {
        header: 'School Name',
        cell: info => {
          return (
            <div>
              <span className="font-medium text-gray-900">
                {info.getValue()}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor('requestsIn', {
        header: 'Requests In',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('catchment', {
        header: 'Catchment',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('nonCatchment', {
        header: 'Non-Catchment',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        cell: ({ row: { toggleExpanded, getIsExpanded } }) => {
          return (
            <CapacityTableActionsCell
              isExpanded={getIsExpanded()}
              expandRow={toggleExpanded}
            />
          );
        },
      }),
    ],
    [columnHelper]
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      paginationType="auto"
      isFetching={false}
      renderExpandedRow={row => renderExpandedRow(tableData[0], row)}
      className="mt-0"
    />
  );
}

export { MatrixReportTable };
