import { ButtonGroup } from '@/components/form/common/button-group';
import { Checkbox } from '@/components/form/common/checkbox';
import { Select } from '@/components/form/common/select';
import { TextInput } from '@/components/form/common/text-input';
import { parentRouterPath } from '@/config/route-paths.config';
import { days } from '@/utils/location-session-utils';
import { ChildMeta } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, format, startOfDay } from 'date-fns';
import { upperFirst } from 'lodash-es';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom';
import { newBookingSchema } from './new-booking.schema';
import { NewBookingFormData } from './new-booking.type';

type NewBookingFormProps = {
  childId: string;
  attendedLocations: ChildMeta['attendedLocations'];
  attendedDays: ChildMeta['attendedDays'];
  startDate: string;
  endDate: string;
};

function NewBookingForm(props: NewBookingFormProps) {
  const { attendedLocations, childId, startDate, endDate } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from');
  const to = searchParams.get('to');
  const selectedDays = searchParams.getAll('days');
  const location = searchParams.get('location');

  const form = useForm({
    resolver: yupResolver(newBookingSchema),
    context: {
      startDate: startDate,
      endDate: endDate,
    },
    defaultValues: {
      locationId:
        location || attendedLocations.length === 1
          ? attendedLocations[0].id
          : '',
      from: from ? format(new Date(from), 'yyyy-MM-dd') : '',
      to: to ? format(new Date(to), 'yyyy-MM-dd') : '',
      days: selectedDays || [],
      range: to ? 'longer' : 'one-day',
    },
  });

  const range = form.watch('range');

  useEffect(() => {
    if (range !== 'longer') {
      form.setValue('days', []);
      form.setValue('to', '');
    }
  }, [form, range]);

  const locationOptions = attendedLocations.map(location => ({
    key: location.id,
    value: location.name,
  }));

  const submitHandler = (data: NewBookingFormData) => {
    const urlWithTermId = generatePath(
      `${
        parentRouterPath.CHILD_AVAILABLE_EXTRA_HOURS
      }?termId=${searchParams.get('termId')}`,
      {
        id: childId,
      }
    );

    const params = [`location=${data.locationId}`];
    const from = startOfDay(new Date(data.from));

    if (data.to) {
      const to = startOfDay(new Date(data.to));
      params.push(`from=${from.toISOString()}`);
      params.push(`to=${to.toISOString()}`);
    } else {
      params.push(`from=${from.toISOString()}`);
    }
    if (data.days && data.days.length > 0) {
      data.days.forEach(day => params.push(`days=${day}`));
    }
    navigate(`${urlWithTermId}&${params.join('&')}`);
  };

  const minDate = format(addDays(new Date(), 1), 'yyyy-MM-dd');

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex h-full flex-1 flex-col space-y-6"
        id="new-booking-form"
      >
        <Select
          name="locationId"
          label="Location"
          options={[{ key: '', value: 'Select..' }, ...locationOptions]}
          disabled={attendedLocations.length === 1}
        />
        <ButtonGroup
          name="range"
          label=""
          options={[
            {
              key: 'one-day',
              value: 'One Day',
            },
            {
              key: 'longer',
              value: 'Longer',
            },
          ]}
        />
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
          <TextInput
            name="from"
            type="date"
            label={range === 'longer' ? 'Date From' : 'Date'}
            min={minDate}
          />
          {range === 'longer' && (
            <TextInput name="to" type="date" label="Date To" min={minDate} />
          )}
        </div>

        {range === 'longer' && (
          <div>
            <p className="label">Select Days</p>
            <div className="grid sm:grid-cols-4 md:grid-cols-7">
              {days.map(day => (
                <Checkbox
                  key={day}
                  name="days"
                  value={day}
                  label={upperFirst(day)}
                />
              ))}
            </div>
            {form.getFieldState('days').error ? (
              <p className="mt-1.5 text-sm text-red-600">
                {form.getFieldState('days').error?.message}
              </p>
            ) : null}
          </div>
        )}
        <div>
          <button className="btn btn-primary">Check Availability</button>
        </div>
      </form>
    </FormProvider>
  );
}

export { NewBookingForm };
