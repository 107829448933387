import { useSchoolYear } from '@/context/school-year.context';
import { useBookingsByLocationId } from '@/hooks/query-hooks/use-bookings-by-location-id';
import { useExtraHoursByLocation } from '@/hooks/query-hooks/use-extra-hours-by-location';
import { getProductsWithCapacity } from '@/utils/extra-hours-utils';
import {
  generateDaysInMonth,
  generateMonthObjects,
} from '@/utils/location-utils';
import { ChevronLeft, ChevronRight } from '@untitled-ui/icons-react';
import { addDays, addMonths, format, isSameDay } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CalendarDayButton } from '../calendar-day-button';
import { FallbackComponent } from '../fallback-component';
import { BookingsTable } from './tables/bookings-table';
import { ExtraHourProductsTable } from './tables/extra-hour-products-table';

function PurchaseExtraHoursCalendar() {
  const { schoolYear } = useSchoolYear();

  const { locationId = '' } = useParams();

  const tableSectionRef = useRef<HTMLDivElement>(null);

  const monthObjects = generateMonthObjects(schoolYear);

  const currentDate = new Date();

  const initialMonthObj = monthObjects.find(
    monthObj =>
      monthObj.month ===
        (currentDate.getMonth() + 1).toString().padStart(2, '0') &&
      monthObj.year === currentDate.getFullYear().toString()
  );

  const [selectedMonthObj, setSelectedMonthObj] = useState(() =>
    initialMonthObj ? initialMonthObj : monthObjects[0]
  );
  const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);

  useEffect(() => {
    tableSectionRef.current?.scrollIntoView();
  }, [selectedDay]);

  useEffect(() => {
    setSelectedDay(undefined);
    setSelectedMonthObj(initialMonthObj ? initialMonthObj : monthObjects[0]);
    // we only want this useEffect to run when schoolYear changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolYear]);

  const {
    data: extraHours,
    isLoading: isExtraHoursLoading,
    error: extraHoursError,
  } = useExtraHoursByLocation(locationId, {
    schoolYearId: schoolYear.id,
    from: addDays(new Date(selectedMonthObj.start), -1),
    to: new Date(selectedMonthObj.end),
  });

  const {
    data: bookings,
    error: bookingsError,
    isLoading: isBookingsLoading,
  } = useBookingsByLocationId(locationId, {
    schoolYearId: schoolYear.id,
    from: addDays(new Date(selectedMonthObj.start), -1),
    to: new Date(selectedMonthObj.end),
  });

  const onNextMonth = () => {
    setSelectedMonthObj(prev => {
      const currentIndex = monthObjects.findIndex(
        month => prev.month === month.month && prev.year === month.year
      );

      if (!monthObjects[currentIndex + 1]) {
        return prev;
      }

      return monthObjects[currentIndex + 1];
    });
  };

  const onPrevMonth = () => {
    setSelectedMonthObj(prev => {
      const currentIndex = monthObjects.findIndex(
        month => prev.month === month.month && prev.year === month.year
      );

      if (!monthObjects[currentIndex - 1]) {
        return prev;
      }

      return monthObjects[currentIndex - 1];
    });
  };

  const isPreviousMonthButtonDisabled =
    selectedMonthObj.label === monthObjects[0].label;

  const isNextMonthButtonDisabled =
    selectedMonthObj.label === monthObjects[monthObjects.length - 1].label;

  const currentMonthDays = generateDaysInMonth(
    selectedMonthObj.year,
    selectedMonthObj.month
  );

  if (extraHoursError || bookingsError) {
    return <FallbackComponent />;
  }

  const selectedDayBookings = bookings?.bookings.filter(booking =>
    isSameDay(
      new Date(booking.date),
      new Date(selectedDay ? selectedDay : new Date())
    )
  );

  const selectedDayProducts = getProductsWithCapacity({
    extraHours,
    selectedDay,
  });

  return (
    <div className="pb-5 lg:flex lg:h-full lg:flex-col">
      <header className="flex flex-col pb-6">
        <div className="my-4 border-t border-gray-200" />
        <div className="flex items-center justify-between">
          <button
            onClick={onPrevMonth}
            disabled={isPreviousMonthButtonDisabled}
            className="btn btn-secondary flex items-center gap-1"
          >
            <ChevronLeft
              className={
                isPreviousMonthButtonDisabled
                  ? 'cursor-not-allowed text-gray-400'
                  : 'text-gray-700'
              }
            />
            {format(addMonths(new Date(selectedMonthObj.start), -1), 'MMM')}
          </button>
          <p className="font-semibold text-gray-700">
            {format(new Date(selectedMonthObj.start), 'yyyy MMMM')}
          </p>
          <button
            onClick={onNextMonth}
            disabled={isNextMonthButtonDisabled}
            className="btn btn-secondary flex items-center gap-1"
          >
            {format(addMonths(new Date(selectedMonthObj.start), 1), 'MMM')}
            <ChevronRight
              className={
                isNextMonthButtonDisabled
                  ? 'cursor-not-allowed text-gray-400'
                  : 'text-gray-700'
              }
            />
          </button>
        </div>
      </header>
      {/** CALENDAR COMPONENT */}
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs leading-6 text-gray-600 lg:flex-none">
          <div className="bg-gray-200 py-2">
            M<span className="sr-only sm:not-sr-only">onday</span>
          </div>
          <div className="bg-gray-200 py-2">
            T<span className="sr-only sm:not-sr-only">uesday</span>
          </div>
          <div className="bg-gray-200 py-2">
            W<span className="sr-only sm:not-sr-only">ednesday</span>
          </div>
          <div className="bg-gray-200 py-2">
            T<span className="sr-only sm:not-sr-only">hursday</span>
          </div>
          <div className="bg-gray-200 py-2">
            F<span className="sr-only sm:not-sr-only">riday</span>
          </div>
          <div className="bg-gray-200 py-2">
            S<span className="sr-only sm:not-sr-only">aturday</span>
          </div>
          <div className="bg-gray-200 py-2">
            S<span className="sr-only sm:not-sr-only">unday</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="grid w-full grid-cols-7 gap-px">
            {currentMonthDays.map(day => {
              if (isExtraHoursLoading || isBookingsLoading) {
                return (
                  <CalendarDayButton
                    key={day.date}
                    day={day}
                    selectedDay={selectedDay}
                    onSelectDay={day => setSelectedDay(day)}
                  />
                );
              }
              return (
                <CalendarDayButton
                  key={day.date}
                  day={day}
                  selectedDay={selectedDay}
                  onSelectDay={day => setSelectedDay(day)}
                  extraHours={extraHours}
                  bookings={bookings?.bookings || []}
                />
              );
            })}
          </div>
        </div>
      </div>
      {selectedDay ? (
        <div ref={tableSectionRef}>
          <div className="my-4 border-t border-gray-200" />
          <div className="flex gap-8">
            <div className="w-1/5">
              <p className="font-semibold text-gray-700">Bookings</p>
              <p className="text-gray-600">
                Bookings and their status for the selected day
              </p>
            </div>
            <div className="w-4/5">
              <p className="font-semibold text-gray-900">
                {format(new Date(selectedDay), 'd MMMM yyyy')}
              </p>
              <BookingsTable bookings={selectedDayBookings} />
            </div>
          </div>
          <div className="my-4 border-t border-gray-200" />
          <div className="flex gap-8">
            <div className="w-1/5">
              <p className="font-semibold text-gray-700">Products</p>
              <p className="text-gray-600">
                Sessions Available for the selected day
              </p>
            </div>
            <div className="w-4/5">
              <p className="font-semibold text-gray-900">
                {format(new Date(selectedDay), 'd MMMM yyyy')}
              </p>
              <div>
                <ExtraHourProductsTable products={selectedDayProducts} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export { PurchaseExtraHoursCalendar };
