import { axiosClient } from '@/libs/axios';
import { ApplicationFilter } from '@/types/application-filter';
import { HttpError } from '@/types/error';
import { PaginateResult } from '@/types/pagination';
import { getApplicationsQueryParams } from '@/utils/application-utils';
import {
  AllApplications,
  Application,
  ApplicationListing,
  BulkEmailApplicantsDto,
  ProgressApplicationsDto,
  BulkUpdateApplicationsDto,
  BulkUpdateApplicationsResponse,
  Choice,
  CreateApplicationDto,
  CreateChoiceDto,
  CreateEmptyApplicationDto,
  GenerateApplicationsCsvDto,
  LocationType,
  MatchingSummary,
  ResetMatchingDto,
  RunMatchingDto,
  UpdateApplicationDtos,
  UpdateChoiceDto,
  UpdateSchoolChoicesDto,
  ValidateUploadApplication,
  ValidateUploadApplicationDto,
  ApplicationProgressionResult,
} from '@admissions-support/types';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';

const listApplications = async (
  schoolYear: string,
  {
    pagination,
    sorting,
    filter,
  }: {
    pagination: PaginationState;
    sorting: SortingState;
    filter: ApplicationFilter;
  },
  applicationsType?: LocationType
): Promise<PaginateResult<ApplicationListing>> => {
  const queryParams = getApplicationsQueryParams({
    schoolYear,
    pagination,
    sorting,
    filter,
    applicationsType,
  });

  const response = await axiosClient.get<PaginateResult<ApplicationListing>>(
    `/applications?${queryParams.join('&')}`
  );

  return response.data;
};

const countApplications = async ({
  filter,
  schoolYear,
  applicationsType,
}: {
  schoolYear: string;
  filter: ApplicationFilter;
  applicationsType?: LocationType;
}): Promise<string[]> => {
  const queryParams = getApplicationsQueryParams({
    schoolYear,
    filter,
    applicationsType,
  });

  const response = await axiosClient.get<string[]>(
    `/applications/count?${queryParams.join(
      '&'
    )}&page=1&limit=1&sortBy=name&orderBy=asc`
  );

  return response.data;
};

const getApplication = async (
  id: string,
  isDisableErrorToast?: boolean
): Promise<AllApplications> => {
  const response = await axiosClient.get<AllApplications>(
    `/applications/${id}`,
    {
      disableErrorToast: isDisableErrorToast,
    }
  );

  return response.data;
};

const validateApplicationFile = async (
  applicationType: LocationType,
  data: ValidateUploadApplicationDto
) => {
  const applicationTypeToUrlPrefix = {
    PRIMARY: 'applications/school',
    SECONDARY: 'applications/school',
    ELC: 'applications',
  };
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<ValidateUploadApplication>,
    ValidateUploadApplicationDto
  >(`${applicationTypeToUrlPrefix[applicationType]}/uploads/validate`, data, {
    disableToast: true,
  });

  return response.data;
};

const processApplicationFile = async (
  applicationType: LocationType,
  data: ValidateUploadApplicationDto
) => {
  const applicationTypeToUrlPrefix = {
    PRIMARY: 'applications/school/uploads/primary',
    SECONDARY: 'applications/school/uploads/secondary',
    ELC: 'applications/uploads',
  };
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<ValidateUploadApplication>,
    ValidateUploadApplicationDto
  >(applicationTypeToUrlPrefix[applicationType], data);

  return response.data;
};

const createApplication = async (
  data: CreateApplicationDto
): Promise<Application> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Application>,
    CreateApplicationDto
  >(`/applications`, data);

  return response.data;
};

const updateChoice = async ({
  applicationId,
  choiceId,
  data,
}: {
  applicationId: string;
  choiceId: string;
  data: UpdateChoiceDto;
}) => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Choice>,
    UpdateChoiceDto
  >(`/applications/${applicationId}/choices/${choiceId}`, data);

  return response.data;
};

const updateApplication = async ({
  applicationId,
  data,
}: {
  applicationId: string;
  data: UpdateApplicationDtos;
}) => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<AllApplications>,
    UpdateApplicationDtos
  >(`/applications/${applicationId}`, data);

  return response.data;
};

/**
 * Send request to obtain applications in csv (based on id passed)
 * @param {string[]} data
 * @returns {Blob}
 */
async function generateApplicationsCsv(
  data: GenerateApplicationsCsvDto
): Promise<Blob> {
  const response = await axiosClient.post(
    `/applications/applications-csv`,
    data,
    {
      disableToast: true,
      responseType: 'blob',
    }
  );

  return response.data;
}

/**
 * Bulk update applications
 * @param data
 */
const bulkUpdateApplications = async (data: BulkUpdateApplicationsDto) => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<BulkUpdateApplicationsResponse>,
    BulkUpdateApplicationsDto
  >(`/applications/bulk-update`, data, {
    disableToast: true,
  });

  return response.data;
};

/**
 * Bulk message applicants
 * @param data
 */
const bulkEmailApplicants = async (data: BulkEmailApplicantsDto) => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<void>,
    BulkEmailApplicantsDto
  >(`/applications/bulk-email`, data, {
    disableToast: true,
  });

  return response.data;
};

/**
 * Progress applications to next school year
 * @param data
 */
const progressApplications = async (data: ProgressApplicationsDto) => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<ApplicationProgressionResult>,
    ProgressApplicationsDto
  >(`/application-progression`, data, {
    disableToast: true,
  });

  return response.data;
};

/**
 *
 * Pass a child identification number and a term id,
 * and this will create an empty draft application
 */
const createApplicationWithChildIdentification = async (
  applicationDto: CreateEmptyApplicationDto
): Promise<Application> => {
  const response = await axiosClient.post<Application>(
    `/applications/initialise`,
    applicationDto
  );

  return response.data;
};

const createChoice = async (
  applicationId: string,
  data: CreateChoiceDto
): Promise<Application> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Application>,
    CreateChoiceDto
  >(`/applications/${applicationId}/choices`, data);

  return response.data;
};

const runMatching = async (data: RunMatchingDto): Promise<MatchingSummary> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<MatchingSummary>,
    RunMatchingDto
  >(`/matching`, data, {
    disableToast: true,
  });

  return response.data;
};

const resetMatching = async (data: ResetMatchingDto): Promise<void> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<void>,
    ResetMatchingDto
  >(`/matching/reset`, data, {
    disableToast: true,
  });

  return response.data;
};

const getApplicationsByChildId = async (
  childId: string
): Promise<Application[]> => {
  const response = await axiosClient.get<Application[]>(
    `/applications/children/${childId}`
  );

  return response.data;
};

const deleteChoice = async ({
  applicationId,
  choiceId,
}: {
  applicationId: string;
  choiceId: string;
}) => {
  await axiosClient.delete<HttpError>(
    `/applications/${applicationId}/choices/${choiceId}`
  );
};

const updateSchoolChoices = async (
  applicationId: string,
  data: UpdateSchoolChoicesDto
) => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Application>,
    UpdateSchoolChoicesDto
  >(`/applications/${applicationId}/school-choices`, data);

  return response.data;
};

export const applicationService = Object.freeze({
  listApplications,
  getApplication,
  bulkUpdateApplications,
  bulkEmailApplicants,
  progressApplications,
  validateApplicationFile,
  processApplicationFile,
  createApplication,
  updateChoice,
  updateApplication,
  createApplicationWithChildIdentification,
  createChoice,
  runMatching,
  resetMatching,
  getApplicationsByChildId,
  countApplications,
  deleteChoice,
  generateApplicationsCsv,
  updateSchoolChoices,
});
