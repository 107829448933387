import { FormProvider, useForm } from 'react-hook-form';
import { PlacementActionType } from '@/components/application/bulk-action/bulk-action.type';
import { bulkPlacementSchema } from '@/components/application/bulk-action/bulk-action.schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonGroup } from '@/components/form/common/button-group';
import { Alert } from '@/components/alert';
import { useBulkUpdateApplicationsMutation } from '@/hooks/update-hooks/use-bulk-update-applications-mutation';
import toast from 'react-hot-toast';
import {
  BulkUpdateApplicationStatusEnum,
  LocationType,
} from '@admissions-support/types';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

type BulkPlacementDecisionFormProps = {
  applicantIds: string[];
  onClose: () => void;
  applicationsType: LocationType;
};

const placementActionOptions: { key: PlacementActionType; value: string }[] = [
  {
    key: 'PLACED',
    value: 'Place Applicants',
  },
  {
    key: 'GRANTED',
    value: 'Grant Placement',
  },
];

export function BulkPlacementDecisionForm({
  applicantIds,
  onClose,
  applicationsType,
}: BulkPlacementDecisionFormProps) {
  const { mutateAsync: bulkUpdateApplications, data } =
    useBulkUpdateApplicationsMutation();
  const form = useForm({
    defaultValues: {
      applicantIds,
      actionType: 'PLACED',
    },
    resolver: yupResolver(bulkPlacementSchema),
  });

  const close = () => {
    form.reset();
    onClose();
  };

  const submit = async () => {
    const values = form.getValues();

    const updatePromise = bulkUpdateApplications({
      status: values.actionType as BulkUpdateApplicationStatusEnum,
      applicationIds: applicantIds,
      applicationsType,
    });

    await toast.promise(updatePromise, {
      loading: 'Updating Applications...',
      success: 'Applications Updated',
      error: 'Failed to update applications',
    });
  };

  return (
    <FormProvider {...form}>
      <form id="bulk-placement-decision-form" className="flex flex-col gap-6">
        <Alert
          type={'warning'}
          text={
            'When you grant a placement, the applicant will be notified via email'
          }
        />

        <ButtonGroup
          name="actionType"
          label={'Action to Perform'}
          options={placementActionOptions}
          value={'place'}
          helperText="Placing will update Matched, Granting will only apply to Placed applicants"
        />

        {data ? (
          <div>
            <p className="text-md font-semibold">
              {applicantIds.length} Applications Processed
            </p>
            <Tooltip
              anchorSelect=".updated"
              content={`${data.updatedApplicationsCount} updated`}
              place="top"
              className="tooltip"
            />
            <Tooltip
              anchorSelect=".not-updated"
              content={`${
                applicantIds.length - data.updatedApplicationsCount
              } not updated`}
              place="top"
              className="tooltip"
            />
            <div className="relative mt-4 flex h-[14px] w-full overflow-hidden rounded-md bg-gray-100">
              <div
                style={{
                  width:
                    (data.updatedApplicationsCount * 100) /
                      applicantIds.length +
                    '%',
                }}
                className="updated cursor-pointer bg-primary-400"
              />
              <div
                style={{
                  width:
                    ((applicantIds.length - data.updatedApplicationsCount) *
                      100) /
                      applicantIds.length +
                    '%',
                }}
                className="not-updated cursor-pointer bg-gray-400"
              />
            </div>
          </div>
        ) : (
          <div className="text-md font-semibold ">
            Action applies to {applicantIds?.length || 0} application(s)
          </div>
        )}

        <div
          className={twMerge(
            'mt-6 grid grid-cols-1 gap-3 sm:grid-cols-2',
            data && 'sm:grid-cols-1'
          )}
        >
          {data ? (
            <button type="button" className="btn btn-primary" onClick={close}>
              View applications
            </button>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={close}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={submit}
              >
                Apply Decision
              </button>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}
