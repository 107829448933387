import { Table } from '@/components/table';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

type PerformanceReportTableRow = {
  rowType: string;
  firstChoice: number;
  secondChoice: number;
  thirdChoice: number;
  other: number;
  none: number;
  all: number;
};

function PerformanceReportTable() {
  const tableData: PerformanceReportTableRow[] = [
    {
      rowType: 'Applications',
      firstChoice: 100,
      secondChoice: 100,
      thirdChoice: 100,
      other: 100,
      none: 100,
      all: 100,
    },
    {
      rowType: '% of Applications',
      firstChoice: 100,
      secondChoice: 100,
      thirdChoice: 100,
      other: 100,
      none: 100,
      all: 100,
    },
  ];
  const columnHelper = createColumnHelper<PerformanceReportTableRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('rowType', {
        header: 'Primary 1',
        cell: info => {
          return (
            <div>
              <span className="font-medium text-gray-900">
                {info.getValue()}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor('firstChoice', {
        header: 'Requests In',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('secondChoice', {
        header: 'Second Choice',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('thirdChoice', {
        header: 'Third Choice',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('other', {
        header: 'Other',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('none', {
        header: 'None',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('all', {
        header: 'All',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
    ],
    [columnHelper]
  );

  return (
    <Table
      columns={columns}
      data={tableData}
      paginationType="auto"
      isFetching={false}
      className="mt-0"
    />
  );
}

type PerformanceReportSchoolTableRow = {
  schoolName: string;
  catchmentPupils: number;
  nonCatchmentPupils: number;
  firstChoice: number;
  secondChoice: number;
  thirdChoice: number;
  other: number;
  none: number;
  total: number;
  limit: number;
};

function PerformanceReportSchoolTable() {
  const tableData: PerformanceReportSchoolTableRow[] = [
    {
      schoolName: 'School 1',
      catchmentPupils: 100,
      nonCatchmentPupils: 100,
      firstChoice: 100,
      secondChoice: 100,
      thirdChoice: 100,
      other: 100,
      none: 100,
      total: 100,
      limit: 100,
    },
    {
      schoolName: 'School 2',
      catchmentPupils: 100,
      nonCatchmentPupils: 100,
      firstChoice: 100,
      secondChoice: 100,
      thirdChoice: 100,
      other: 100,
      none: 100,
      total: 100,
      limit: 100,
    },
  ];

  const columnHelper = createColumnHelper<PerformanceReportSchoolTableRow>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('schoolName', {
        header: 'School Name',
        cell: info => {
          return <div>{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('catchmentPupils', {
        header: 'Catchment Pupils',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('nonCatchmentPupils', {
        header: 'Non-Catchment Pupils',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('firstChoice', {
        header: 'First Choice',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('secondChoice', {
        header: 'Second Choice',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('thirdChoice', {
        header: 'Third Choice',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('other', {
        header: 'Other',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('none', {
        header: 'None',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('total', {
        header: 'All',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('limit', {
        header: 'Limit',
        cell: info => {
          return <div className="font-mono">{info.getValue()}</div>;
        },
      }),
    ],
    [columnHelper]
  );

  return <Table columns={columns} data={tableData} paginationType="auto" />;
}

export { PerformanceReportTable, PerformanceReportSchoolTable };
