import { useSessions } from '@/hooks/query-hooks/use-sessions';
import {
  formatRequestedDaysAsBooleanMap,
  getFirstAdditionalSessionId,
  getFirstSessionId,
} from '@/utils/application-utils';
import { arrayify } from '@/utils/array-utils';
import {
  getChoiceLocationsName,
  getLocationsByChoice,
  getOperatingDaysForSessions,
  listRequestedDaysInSessionSchedule,
} from '@/utils/choice-utils';
import { formatCompactScheduleDays } from '@/utils/format-operating-days';
import {
  areAllDaysAreEqual,
  findFirstNonNullDay,
} from '@/utils/location-session-utils';
import {
  Choice,
  OperatingDaySession,
  RequestedSessionSchedule,
  Session,
  SessionTiming,
} from '@admissions-support/types';
import { capitalize, uniq } from 'lodash-es';
import { Alert } from '../alert';
import { Spinner } from '../spinner';
import { SingleItem } from './placement-preferences/choices/single-item';
import { SessionRadioBoxOption } from './session-radio-box-option';
import { format } from 'date-fns';
import { Tag } from '../tag';

type LocationChoiceProps = {
  choice: Choice;
  schoolYearId: string;
};

function LocationChoice(props: LocationChoiceProps) {
  const { choice, schoolYearId } = props;

  const choiceTitle = getChoiceLocationsName(choice);
  const locations = getLocationsByChoice(choice);
  const isSingleLocation = locations.length === 1;
  const primaryLocationId = choice.location.id.toString();

  const {
    isLoading: isSessionDataLoading,
    data: sessions,
    isError,
    isSuccess: isSessionsSuccess,
  } = useSessions(primaryLocationId, schoolYearId);

  const {
    isLoading: isSecondaryLocationSessionsLoading,
    data: sessionsForSecondaryLocation,
    isError: isSecondaryLocationSessionsError,
    isSuccess: isSecondarySessionsSuccess,
  } = useSessions(isSingleLocation ? '' : locations[1].id, schoolYearId, {
    enabled: !isSingleLocation,
  });

  if (isSessionDataLoading || isSecondaryLocationSessionsLoading) {
    return (
      <div className="dark-gray-container">
        <p className="text-lg text-gray-900">{choiceTitle}</p>
        <Spinner className="mt-4" />
      </div>
    );
  }

  if (isError || isSecondaryLocationSessionsError || !isSessionsSuccess) {
    return (
      <Alert
        type="error"
        text={<p>Error fetching sessions</p>}
        className="mt-5"
      />
    );
  }

  // The choice is Child Minder or Partner Provider
  if (sessions.length < 1) {
    return (
      <div className="dark-gray-container">
        <p className="text-lg text-gray-900">{choiceTitle}</p>
      </div>
    );
  }

  // you can have different sessions for each day. however there are common properties,
  // which must be the same for each selected session; session-type, model
  // we find the first non-null session on the attendance pattern to figure out those fields
  const firstSessionId = getFirstSessionId(choice);
  const firstAdditionalSessionId = getFirstAdditionalSessionId(choice);
  const mergedSessions: Session[] = isSecondarySessionsSuccess
    ? [...sessions, ...sessionsForSecondaryLocation]
    : [...sessions];
  const session = mergedSessions.find(
    session => session.id.toString() === firstSessionId
  );
  const additionalSession = mergedSessions.find(
    session => session.id.toString() === firstAdditionalSessionId
  );

  if (!session) {
    return (
      <Alert type="error" text={<p>No sessions found!</p>} className="mt-5" />
    );
  }

  const hasSameDays = areAllDaysAreEqual(session.times);

  const coreSessionsDays = listRequestedDaysInSessionSchedule(choice.sessions);
  const additionalSessionsDays = getOperatingDaysForSessions(
    choice.additionalSessions
  );
  const splitPlacementSessionsDays = listRequestedDaysInSessionSchedule(
    choice.splitPlacement?.sessions
  );

  const secondaryLocationId: string | undefined =
    splitPlacementSessionsDays.length > 0 &&
    choice.splitPlacement &&
    choice.splitPlacement.location
      ? choice.splitPlacement.location.id.toString()
      : choice.splitPlacement?.additionalSessionLocation?.id.toString();

  const additionalSessionLocationId: string | null =
    additionalSessionsDays.length > 0
      ? choice.splitPlacement && choice.splitPlacement.additionalSessionLocation
        ? choice.splitPlacement.additionalSessionLocation.id.toString()
        : null
      : null;

  const listOfNonNullSessionIds = coreSessionsDays
    .map(day => choice.sessions[day as keyof OperatingDaySession])
    .filter(sessionId => sessionId);
  const nbOfUniqueSessions = uniq(listOfNonNullSessionIds).length;

  function findCurrentSession<T extends string>(
    schedule: Record<
      T,
      RequestedSessionSchedule[keyof RequestedSessionSchedule]
    >,
    day: T
  ): Session {
    const arrayableSessionId = schedule[day];
    const currentSession =
      arrayableSessionId &&
      mergedSessions.find(
        session => session.id === arrayify(arrayableSessionId)[0]?.toString()
      );

    if (!currentSession) {
      throw new Error('Session not found!');
    }

    return currentSession;
  }

  const getChoiceDayTitle = (day: string): string => {
    if (day === 'any') {
      const anyDays = Object.entries(choice.schedule || {})
        .filter(([_, value]) => !!value)
        .map(([key]) => key);

      return `Any ${
        anyDays.length ? `(${anyDays.concat(day).join(', ')})` : ''
      }`;
    }

    return capitalize(day);
  };

  const sessionTypeToDisplay = additionalSession
    ? `${session.type.name} + ${additionalSession.type.name}`
    : session.type.name;

  if (!isSingleLocation) {
    return (
      <div className="dark-gray-container">
        <p className="text-lg text-gray-900">{choiceTitle}</p>
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
            <ListItem name="Model" value={session.model.name} />
            <ListItem name="Session Type" value={sessionTypeToDisplay} />
          </div>
        </dl>
        <div className="space-y-4">
          <div className="light-gray-container">
            <p className="text-lg font-semibold text-gray-700">
              {locations[0].name}
            </p>
            <div className="grid grid-cols-3">
              {coreSessionsDays.map(day => {
                const currentSession = findCurrentSession(choice.sessions, day);
                const title = getChoiceDayTitle(day);

                return (
                  <SessionRadioBoxOption
                    key={day}
                    title={title}
                    start={findFirstNonNullDay(currentSession.times).start}
                    end={findFirstNonNullDay(currentSession.times).end}
                  />
                );
              })}
              {/* Display additional sessions here only if additional sessions are at the primary location */}
              {additionalSessionLocationId === primaryLocationId &&
                additionalSessionsDays.map(day => {
                  const currentSession = findCurrentSession(
                    choice.additionalSessions,
                    day
                  );
                  const title = getChoiceDayTitle(day);
                  return (
                    <SessionRadioBoxOption
                      key={day}
                      title={title}
                      start={findFirstNonNullDay(currentSession.times).start}
                      end={findFirstNonNullDay(currentSession.times).end}
                    />
                  );
                })}
            </div>
          </div>
          <div className="light-gray-container">
            <p className="text-lg font-semibold text-gray-700">
              {locations[1].name}
            </p>
            <div className="grid grid-cols-3 gap-3">
              {choice.splitPlacement &&
                splitPlacementSessionsDays.map(day => {
                  if (!choice.splitPlacement?.sessions) {
                    return null;
                  }

                  const currentSession = findCurrentSession(
                    choice.splitPlacement.sessions,
                    day
                  );
                  const title = getChoiceDayTitle(day);

                  return (
                    <SessionRadioBoxOption
                      key={day}
                      title={title}
                      start={findFirstNonNullDay(currentSession.times).start}
                      end={findFirstNonNullDay(currentSession.times).end}
                    />
                  );
                })}
              {additionalSessionLocationId === secondaryLocationId &&
                additionalSessionsDays.map(day => {
                  const currentSession = findCurrentSession(
                    choice.additionalSessions,
                    day
                  );
                  const title = getChoiceDayTitle(day);
                  return (
                    <SessionRadioBoxOption
                      key={day}
                      title={title}
                      start={findFirstNonNullDay(currentSession.times).start}
                      end={findFirstNonNullDay(currentSession.times).end}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  /* Display grouped session with single location */
  if (nbOfUniqueSessions === 1 && hasSameDays && isSingleLocation) {
    const operatingDaysToDisplay = formatCompactScheduleDays(
      formatRequestedDaysAsBooleanMap(coreSessionsDays)
    );

    let anyDays:
      | {
          day: string;
          timing: { start: string; end: string } | null;
        }[]
      | undefined = undefined;

    if (operatingDaysToDisplay === 'Any') {
      const anySessions = Object.entries(choice.schedule || {}).filter(
        ([_, value]) => !!value
      );

      anyDays = anySessions.map(([day, sessionId]) => {
        const currentSession = mergedSessions.find(
          session => session.id === sessionId
        );
        const timing = currentSession?.times[day as keyof SessionTiming];
        return {
          day: capitalize(day),
          timing: timing
            ? {
                start: format(timing.start as Date, 'HH:mm'),
                end: format(timing.end as Date, 'HH:mm'),
              }
            : null,
        };
      });
    }

    return (
      <div className="dark-gray-container">
        <div className="flex gap-3">
          <p className="text-lg text-gray-900">{choiceTitle}</p>
          {anyDays?.length ? <Tag type="default">Any</Tag> : null}
        </div>
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
            <ListItem name="Model" value={session.model.name} />
            <ListItem name="Session Type" value={sessionTypeToDisplay} />
          </div>
        </dl>
        <div className="light-gray-container">
          <div className="grid grid-cols-3 gap-3">
            {anyDays?.length ? (
              <>
                {anyDays.map(day => (
                  <SessionRadioBoxOption
                    key={day.day}
                    title={day.day}
                    start={day.timing?.start}
                    end={day.timing?.end}
                  />
                ))}
              </>
            ) : (
              <SessionRadioBoxOption
                title={operatingDaysToDisplay}
                start={findFirstNonNullDay(session.times).start}
                end={findFirstNonNullDay(session.times).end}
              />
            )}
            {additionalSessionsDays.map(day => {
              const currentSession = findCurrentSession(
                choice.additionalSessions,
                day
              );
              const title = getChoiceDayTitle(day);
              return (
                <SessionRadioBoxOption
                  key={day}
                  title={title}
                  start={findFirstNonNullDay(currentSession.times).start}
                  end={findFirstNonNullDay(currentSession.times).end}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dark-gray-container">
      <p className="text-lg text-gray-900">{choiceTitle}</p>
      <dl className="divide-y divide-gray-100">
        <div className="px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
          <ListItem name="Model" value={session.model.name} />
          <ListItem name="Session Type" value={sessionTypeToDisplay} />
        </div>
      </dl>
      <div className="space-y-4">
        {coreSessionsDays.map(day => {
          const currentSession = findCurrentSession(choice.sessions, day);
          const title = getChoiceDayTitle(day);

          return (
            <SingleItem
              key={day}
              content={
                <SessionRadioBoxOption
                  title={title}
                  start={findFirstNonNullDay(currentSession.times).start}
                  end={findFirstNonNullDay(currentSession.times).end}
                />
              }
            />
          );
        })}
      </div>
    </div>
  );
}

function ListItem(props: { name: string; value: string }) {
  const { name, value } = props;

  return (
    <>
      <dt className="text-md font-medium leading-6">{name}</dt>
      <dd className="text-md mt-1 leading-6 sm:col-span-3 sm:mt-0">{value}</dd>
    </>
  );
}

export { LocationChoice };
