import { Select } from './form/common/select';

const yearStages = [
  { label: 'Denominational', value: 'romanCatholic' },
  { label: 'Anglican', value: 'anglican' },
  { label: 'Methodist', value: 'methodist' },
  { label: 'Other', value: 'other' },
];

type DenominationSelectProps = {
  disabled?: boolean;
};

function DenominationSelect({ disabled }: DenominationSelectProps) {
  return (
    <Select
      name="denomination"
      label="Denomination"
      options={yearStages.map(stage => ({
        key: stage.value,
        value: stage.label,
      }))}
      disabled={disabled}
    />
  );
}

export { DenominationSelect };
