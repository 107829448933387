export const SCHOOL_STAGE_OPTIONS = Object.freeze<
  { key: string; value: string }[]
>([
  { value: 'Primary', key: 'PRIMARY' },
  { value: 'Secondary', key: 'SECONDARY' },
]);

export const SCHOOL_PROVIDER_TYPE_OPTIONS = Object.freeze<
  { key: string; value: string }[]
>([
  { value: 'Non-denominational', key: 'NON_DENOMINATIONAL' },
  { value: 'Roman Catholic', key: 'ROMAN_CATHOLIC' },
]);
