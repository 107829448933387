import { Alert } from '@/components/alert';
import { useBulkEmailApplicantsMutation } from '@/hooks/use-bulk-message-applications-mutation';
import {
  BulkEmailApplicantsDto,
  EmailTemplateEnum,
  LocationType,
} from '@admissions-support/types';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

type InviteToSchoolFormProps = {
  applicantIds: string[];
  onClose: () => void;
  applicationsType: LocationType;
};

const emailTemplateId = 'InviteToSubmitSchoolApplication';

function InviteToSchoolForm(props: InviteToSchoolFormProps) {
  const { applicantIds, onClose, applicationsType } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutateAsync: sendBulkEmail } = useBulkEmailApplicantsMutation();

  const onSubmit = async () => {
    setIsSubmitting(true);
    const payload: BulkEmailApplicantsDto = {
      applicationIds: applicantIds,
      emailTemplateId: emailTemplateId as EmailTemplateEnum,
      applicationsType,
    };

    const sendEmailPromise = sendBulkEmail(payload);
    await toast.promise(sendEmailPromise, {
      loading: 'Sending emails...',
      success: 'Emails sent successfully',
      error: 'Failed to send emails',
    });

    setIsSubmitting(false);

    onClose();
  };

  return (
    <div>
      <Alert
        type="warning"
        title="Attention"
        text="Parents will be invited to submit an application. Where possible, the application will be pre-populated with existing information"
      />
      <p className="mb-6 mt-5 text-sm text-gray-700">
        Action applies to {applicantIds.length} selected applications{' '}
      </p>
      <div className="flex w-full gap-3">
        <button
          type="button"
          className="btn btn-secondary flex-1"
          onClick={onClose}
        >
          Cancel
        </button>

        <button
          type="button"
          disabled={isSubmitting}
          className="btn btn-primary flex-1"
          onClick={onSubmit}
        >
          Apply Decision
        </button>
      </div>
    </div>
  );
}

export { InviteToSchoolForm };
