import { PageTitle } from '@/components/page-title';
import { UpsertCatchmentAreasFormData } from '@/components/settings/catchment-areas/form/upsert-catchment-areas.type';
import { useUpsertCatchmentAreasForm } from '@/components/settings/catchment-areas/form/use-upsert-catchment-areas.form';
import { UploadCatchmentAreas } from '@/components/settings/catchment-areas/upload-catchment-areas';
import { useOrganisationData } from '@/context/organisation-control.context';
import { useUpdateCatchmentArea } from '@/hooks/update-hooks/use-update-catchment-area';
import { SchoolLocationType } from '@admissions-support/types';
import { FormProvider } from 'react-hook-form';

function CatchmentAreas() {
  const { mutateAsync: updateCatchmentArea, isPending: isUpdating } =
    useUpdateCatchmentArea();

  const { data: organisation } = useOrganisationData();

  const onSubmit = async ({
    providerType,
    stage,
    ...form
  }: UpsertCatchmentAreasFormData) => {
    if (
      !['NON_DENOMINATIONAL', 'ROMAN_CATHOLIC'].includes(providerType) ||
      !['PRIMARY', 'SECONDARY'].includes(stage)
    ) {
      console.error(
        `Unrecognised providerType '${providerType}' or stage '${stage}'`
      );
      return;
    }

    const denominationValue =
      providerType === 'ROMAN_CATHOLIC' ? '' : '_NON_DENOMINATIONAL';
    const schoolLocationType: SchoolLocationType = `${stage}${denominationValue}`;

    await updateCatchmentArea({
      geoJsonFileKey: form.catchmentAreaFiles[0].key,
      schoolLocationType,
    });
  };

  const { form, handleSubmit } = useUpsertCatchmentAreasForm({
    onSubmit,
  });

  return (
    <div>
      <PageTitle title="Catchment Areas" variant="gray">
        <button
          className="btn btn-primary"
          type="submit"
          form="catchment-areas-form"
        >
          Save
        </button>
      </PageTitle>
      <div className="mt-6">
        <FormProvider {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            id="catchment-areas-form"
          >
            <UploadCatchmentAreas
              isDisabled={isUpdating}
              catchmentAreas={organisation.catchmentAreas}
            />
          </form>
        </FormProvider>
      </div>
    </div>
  );
}

export { CatchmentAreas };
