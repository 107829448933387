import { Alert } from '@/components/alert';
import { FormColEmptyState } from '@/components/form-col-empty-state';
import { LocationCapacityForm } from '@/components/form/location-capacity.form';
import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { Spinner } from '@/components/spinner';
import { locationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { useLocationOperation } from '@/hooks/query-hooks/use-operation';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useRatios } from '@/hooks/query-hooks/use-ratios';
import { useUpdateOperationMutation } from '@/hooks/update-hooks/use-update-operation-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

function LocationCapacity() {
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();

  const params = useParams<{ locationId: string }>();
  const locationId = params.locationId || '';
  const {
    isLoading: isLocationLoading,
    data: location,
    isError,
  } = useLocation(locationId);

  useEffect(() => {
    if (isError) {
      navigate(staffRouterPath.LOCATIONS);
    }
  }, [isError, navigate]);

  const { isLoading: isRatioLoading, data: ratios } = useRatios();

  const { isLoading: isOperationLoading, data: operation } =
    useLocationOperation(locationId);

  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateOperationMutation(
      {
        operationId: operation?.id || '',
      },
      {
        onSuccess: async operation => {
          await queryClient.invalidateQueries({
            queryKey: locationKey.operation(
              locationId,
              String(operation.schoolYear.id)
            ),
          });

          // Invalidate extra hours and bookings for the location
          await queryClient.invalidateQueries({
            predicate: query =>
              query.queryKey[0] === 'extra-hours' &&
              query.queryKey[1] === operation.location.id,
          });
          await queryClient.invalidateQueries({
            predicate: query =>
              query.queryKey[0] === 'booking' &&
              query.queryKey[1] === operation.location.id,
          });
        },
      }
    );

  const isPageLoading =
    isLocationLoading || isOperationLoading || isRatioLoading;

  if (isPageLoading) {
    return (
      <>
        <PageTitle
          title={location?.name || ''}
          variant="gray"
          className="border-none"
          description={
            location?.name ? (
              <SchoolYearFilter className="-mt-1 w-64" />
            ) : undefined
          }
        >
          <div className="flex h-[68px] gap-3" aria-hidden="true"></div>
        </PageTitle>
        <LocationTabNavigation id={params.locationId || ''} />
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      </>
    );
  }

  if (!location || isError) {
    return <Navigate to={staffRouterPath.LOCATIONS} />;
  }

  if (!operation) {
    return (
      <>
        <PageTitle
          title={location.name}
          variant="gray"
          className="border-none"
          description={<SchoolYearFilter className="-mt-1 w-64" />}
        />
        <LocationTabNavigation id={params.locationId || ''} />
        <p className="mt-6 text-center text-sm">
          Settings are not available for this term
        </p>
      </>
    );
  }

  const hasRatios = Array.isArray(ratios) && ratios.length > 0;

  const isFormDisabled =
    isUpdating || !hasPermission([Permission['location:update']]);

  return (
    <>
      <PageTitle
        title={location.name}
        variant="gray"
        className="border-none"
        description={<SchoolYearFilter className="-mt-1 w-64" />}
      >
        <div className="flex gap-3">
          <button
            className="btn btn-primary"
            form="update-capacity-form"
            disabled={isFormDisabled}
          >
            Save
          </button>
        </div>
      </PageTitle>
      <LocationTabNavigation id={params.locationId || ''} />
      <div className="two-col-form">
        <div>
          <p className="text-md font-medium leading-7 text-gray-900">
            Physical Capability
          </p>
          <p className="text-md leading-6 text-gray-600">
            Specify location capacity for scenario planning, and define physical
            limits and ages supported.
          </p>
        </div>
        <div className="col-span-2 space-y-4">
          {hasRatios ? (
            <>
              <Alert
                type="warning"
                text={
                  <>
                    <p className="mb-1 font-medium">Attention!</p>
                    <p>
                      Disabling an age ratio will remove all <b>sessions</b> and
                      allocated <b>resources</b> relating to that ratio.
                    </p>
                  </>
                }
              />

              <LocationCapacityForm
                initialData={operation}
                ratios={ratios}
                onSubmit={update}
                disabled={isFormDisabled}
              />
            </>
          ) : (
            <FormColEmptyState
              title="No Ratios Found"
              btnText="Create Ratio"
              slug={staffRouterPath.NEW_RATIO}
              description="Before you're able to define capacity for this location, please define some staffing ratios."
            />
          )}
        </div>
      </div>
    </>
  );
}

export { LocationCapacity };
