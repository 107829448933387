import { useLeaveModal } from '@/hooks/use-leave-modal';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { SchoolLocation } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { LocationDetailsSection } from './upsert-location/location-details.section';
import { KeyContactSection } from './upsert-location/key-contact.section';
import { FeederSchoolsSection } from './upsert-location/feeder-schools.section';
import { useLocation, useSearchParams } from 'react-router-dom';
import { DeepPartial } from '@/types/util';
import { LocationFormData } from './location.form';
import { getSchoolProviderType } from '@/utils/location-utils';

const schema = yup.object({
  name: yup.string().required().label('Name'),
  providerType: yup.string().required().label('Provider Type'),
  contactNumber: yup.string().required().label('Contact Number'),
  website: yup.string().nullable().label('Website'),
  seedCode: yup.string().nullable().label('Seed Code'),
  address: yup
    .object({
      streetAddress: yup.string().required().label('Address Line 1'),
      streetAddressTwo: yup.string().nullable().label('Address Line 2'),
      city: yup.string().nullable().label('City'),
      postcode: yup.string().required().label('Postcode'),
    })
    .required(),
  keyContact: yup.object({
    firstName: yup.string().required().label('First Name'),
    lastName: yup.string().required().label('Last Name'),
    email: yup.string().email().required().label('Email'),
  }),
  feederSchools: yup.array(yup.string()).label('Feeder Schools'),
});

export type SchoolLocationFormData = yup.InferType<typeof schema>;

type SchoolLocationFormProps = {
  onSubmit: (data: SchoolLocationFormData) => any;
  initialData?: SchoolLocation;
  isLoading?: boolean;
};

function SchoolLocationForm(props: SchoolLocationFormProps) {
  const { initialData, onSubmit } = props;

  const location = useLocation();

  const navigationState = location.state as DeepPartial<LocationFormData>;

  const [searchParams] = useSearchParams();

  const type = searchParams.get('type');

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: initialData?.name || navigationState?.name || '',
      providerType: getSchoolProviderType(initialData?.type),
      contactNumber:
        initialData?.number || navigationState?.contactNumber || '',
      website: initialData?.website || navigationState?.website || '',
      seedCode: initialData?.seedCode || navigationState?.seedCode || '',
      address: {
        streetAddress:
          initialData?.address.streetAddress ||
          navigationState?.address?.streetAddress ||
          '',
        streetAddressTwo:
          initialData?.address.streetAddressTwo ||
          navigationState?.address?.streetAddressTwo ||
          '',
        city: initialData?.address.city || navigationState?.address?.city || '',
        postcode:
          initialData?.address.postcode ||
          navigationState?.address?.postcode ||
          '',
      },
      keyContact: {
        firstName:
          initialData?.keyContact.firstName ||
          navigationState?.keyContact?.firstName ||
          '',
        lastName:
          initialData?.keyContact.lastName ||
          navigationState?.keyContact?.lastName ||
          '',
        email:
          initialData?.keyContact.email ||
          navigationState?.keyContact?.email ||
          '',
      },
      feederSchools:
        initialData?.feederSchools?.map(feederSchool =>
          feederSchool.id.toString()
        ) || [],
    },
  });

  useLeaveModal({
    show: form.formState.isDirty && !!initialData,
  });

  const submitHandler = async (data: any) => {
    //TODO: needs to update the package in order to avoid this
    const convertedData = data as SchoolLocationFormData;

    if (!convertedData.address.streetAddressTwo) {
      convertedData.address.streetAddressTwo = null;
    }

    if (!convertedData.address.city) {
      convertedData.address.city = null;
    }

    if (!convertedData.website) {
      delete convertedData.website;
    }

    if (!convertedData.seedCode) {
      convertedData.seedCode = null;
    }

    try {
      await onSubmit(convertedData);
      form.reset(form.getValues(), { keepValues: true });
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex h-full flex-1 flex-col"
        id="upsert-school-location-form"
      >
        <LocationDetailsSection
          isEdit={Boolean(initialData)}
          isSchoolLocation
        />
        <KeyContactSection />
        {type?.includes('SECONDARY') ||
          (initialData?.type.includes('SECONDARY') && <FeederSchoolsSection />)}
      </form>
    </FormProvider>
  );
}

export { SchoolLocationForm };
