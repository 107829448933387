import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';
import { useSchoolYear } from '@/context/school-year.context';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { ChoiceFilter, LocationType } from '@admissions-support/types';
import { PopoverGroup } from '@headlessui/react';
import { Tag } from '../tag';
import { ApplicationStatusTag } from './application-status-tag';
import { ChoiceStatusTag } from './choice-status-tag';
import { ChoiceStatusesFilter, choiceStatuses } from './choice-statuses-filter';
import { ChoicesFilter, choices } from './choices-filter';
import { IntakeFilter } from './intake-filter';
import { StatusFilter, statuses } from './status-filter';
import { FilterItem } from '../filter-item';
import { CohortFilter } from './cohorts-filter';
import { YearStageFilter, yearStages } from './yearstage-filter';
import { LocationSearchFilter } from '../location-search-filter';

type ApplicationsFilterRowProps = {
  applicationsType?: LocationType;
};

function ApplicationsFilterRow(props: ApplicationsFilterRowProps) {
  const { applicationsType } = props;

  const { filter, setFilter, type } = useApplicationControl();
  const { data: locationsData, isLoading } = useLocations({
    type: applicationsType ? applicationsType : undefined,
  });
  const { schoolYear } = useSchoolYear();

  const intakes = schoolYear.intakes.map(intake => ({
    label: intake.name,
    value: intake.id,
  }));

  const cohorts = schoolYear.cohorts.map(cohort => ({
    label: cohort.cohort.name,
    value: cohort.cohort.id,
  }));

  intakes.push({
    label: 'School Year',
    value: 'school-year',
  });

  const handleResetStatus = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      statuses: defaultApplicationControl.filter.statuses,
    }));
  };

  const handleResetChoices = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      choices: defaultApplicationControl.filter.choices,
    }));
  };

  const handleResetChoiceStatuses = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      choiceStatuses: defaultApplicationControl.filter.choiceStatuses,
    }));
  };

  const handleResetIntake = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      intakes: defaultApplicationControl.filter.intakes,
    }));
  };

  const handleResetCohorts = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      cohorts: defaultApplicationControl.filter.cohorts,
    }));
  };

  const handleResetYearStages = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      yearStage: defaultApplicationControl.filter.yearStages,
    }));
  };

  function SelectedStatuses() {
    const selectedStatuses = statuses
      .filter(status => filter.statuses.includes(status.value))
      .sort(
        (a, b) =>
          filter.statuses.indexOf(a.value) - filter.statuses.indexOf(b.value)
      );

    return (
      <div className="space-x-1">
        <ApplicationStatusTag status={selectedStatuses[0].value}>
          {selectedStatuses[0].label}
        </ApplicationStatusTag>
      </div>
    );
  }

  function SelectedChoices() {
    const selectedChoices = choices
      .filter(choice =>
        filter.choices.includes(parseInt(choice.value) as ChoiceFilter)
      )
      .sort(
        (a, b) =>
          filter.choices.indexOf(parseInt(a.value) as ChoiceFilter) -
          filter.choices.indexOf(parseInt(b.value) as ChoiceFilter)
      );
    const getChoice = (nbChoice: number | null) => {
      if (nbChoice === 1) {
        return (
          <Tag type="success" className="px-1 py-0 text-xs">
            1st choice
          </Tag>
        );
      }

      if (nbChoice === 2) {
        return (
          <Tag type="warning" className="px-1 py-0 text-xs">
            2nd choice
          </Tag>
        );
      }

      if (nbChoice === 3) {
        return (
          <Tag type="error" className="px-1 py-0 text-xs">
            3rd choice
          </Tag>
        );
      }

      return (
        <Tag type="default" className="px-1 py-0 text-xs">
          None
        </Tag>
      );
    };

    return (
      <div className="space-x-1">
        {getChoice(parseInt(selectedChoices[0].value))}
      </div>
    );
  }

  function SelectedChoiceStatuses() {
    const selectedStatuses = choiceStatuses
      .filter(status => filter.choiceStatuses.includes(status.value))
      .sort(
        (a, b) =>
          filter.choiceStatuses.indexOf(a.value) -
          filter.choiceStatuses.indexOf(b.value)
      );

    return (
      <div className="space-x-1">
        <ChoiceStatusTag status={selectedStatuses[0].value}>
          {selectedStatuses[0].label}
        </ChoiceStatusTag>
      </div>
    );
  }

  function SelectedIntakes() {
    const selectedIntakes = intakes
      .filter(intake => filter.intakes.includes(intake.value))
      .sort(
        (a, b) =>
          filter.intakes.indexOf(a.value) - filter.intakes.indexOf(b.value)
      );

    return (
      <Tag
        key={selectedIntakes[0].value}
        type="outline"
        className="px-1 py-0 text-xs"
      >
        {selectedIntakes[0].label}
      </Tag>
    );
  }

  function SelectedYearStages() {
    const selectedYearStages = yearStages
      .filter(yearStage => filter.yearStages.includes(yearStage.value))
      .sort(
        (a, b) =>
          filter.yearStages.indexOf(a.value) -
          filter.yearStages.indexOf(b.value)
      );

    return (
      <Tag
        key={selectedYearStages[0].value}
        type="outline"
        className="px-1 py-0 text-xs"
      >
        {selectedYearStages[0].label}
      </Tag>
    );
  }

  function SelectedCohorts() {
    const selectedCohorts = cohorts
      .filter(cohort => filter.cohorts.includes(cohort.value.toString()))
      .sort(
        (a, b) =>
          filter.cohorts.indexOf(a.value.toString()) -
          filter.cohorts.indexOf(b.value.toString())
      );

    return (
      <Tag
        key={selectedCohorts[0].value.toString()}
        type="outline"
        className="px-1 py-0 text-xs"
      >
        {selectedCohorts[0].label}
      </Tag>
    );
  }

  return (
    <div className="mt-3 border-t border-gray-100 py-3">
      <PopoverGroup className="hidden sm:flex sm:flex-wrap sm:items-baseline sm:gap-2">
        <FilterItem
          label="Status"
          counter={filter.statuses.length}
          reset={handleResetStatus}
          renderSelectedItems={<SelectedStatuses />}
        >
          <StatusFilter />
        </FilterItem>
        <FilterItem
          label="Choice"
          counter={filter.choices.length}
          reset={handleResetChoices}
          renderSelectedItems={<SelectedChoices />}
        >
          <ChoicesFilter />
        </FilterItem>
        {!isLoading && type === 'main' && <LocationSearchFilter />}
        <FilterItem
          label="Choice Status"
          counter={filter.choiceStatuses.length}
          reset={handleResetChoiceStatuses}
          renderSelectedItems={<SelectedChoiceStatuses />}
        >
          <ChoiceStatusesFilter />
        </FilterItem>

        {applicationsType !== 'PRIMARY' && applicationsType !== 'SECONDARY' ? (
          <>
            <FilterItem
              label="Intakes"
              counter={filter.intakes.length}
              reset={handleResetIntake}
              renderSelectedItems={<SelectedIntakes />}
            >
              <IntakeFilter />
            </FilterItem>
            <FilterItem
              label="Cohorts"
              counter={filter.cohorts.length}
              reset={handleResetCohorts}
              renderSelectedItems={<SelectedCohorts />}
            >
              <CohortFilter />
            </FilterItem>
          </>
        ) : (
          <FilterItem
            label="Year Stages"
            counter={filter.yearStages.length || 0}
            reset={handleResetYearStages}
            renderSelectedItems={<SelectedYearStages />}
          >
            <YearStageFilter applicationsType={applicationsType} />
          </FilterItem>
        )}
      </PopoverGroup>
    </div>
  );
}

export { ApplicationsFilterRow };
