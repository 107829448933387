import { Box } from '@/components/box';
import { StatusTag } from '@/components/status-tag';
import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

type Template = {
  name: string;
  visibility: 'ORGANISATION' | 'ONLY_YOU';
  url: string;
};

const templates: Template[] = [
  {
    name: 'Allocation Report',
    visibility: 'ORGANISATION',
    url: staffRouterPath.REPORTING_ALLOCATION,
  },
  {
    name: 'Capacity Planning',
    visibility: 'ORGANISATION',
    url: staffRouterPath.REPORTING_CAPACITY,
  },
  {
    name: 'Demand Summary',
    visibility: 'ORGANISATION',
    url: staffRouterPath.REPORTING_DEMAND,
  },
  {
    name: 'Summary Report',
    visibility: 'ORGANISATION',
    url: staffRouterPath.REPORTING_SUMMARY,
  },
  {
    name: 'Matrix Report',
    visibility: 'ORGANISATION',
    url: staffRouterPath.REPORTING_MATRIX,
  },
  {
    name: 'Performance Report',
    visibility: 'ORGANISATION',
    url: staffRouterPath.REPORTING_PERFORMANCE,
  },
  {
    name: 'Decision Analysis Report',
    visibility: 'ORGANISATION',
    url: staffRouterPath.REPORTING_DECISION_ANALYSIS,
  },
];

function ReportsTable() {
  const columnHelper = createColumnHelper<Template>();
  const columns = [
    columnHelper.accessor('name', {
      id: 'templateName',
      cell: info => {
        const name = info.getValue();
        return (
          <span className="text-sm font-medium text-gray-600">{name}</span>
        );
      },
      header: () => <span className="text-xs text-gray-600">Report</span>,
      enableSorting: true,
    }),
    columnHelper.accessor('visibility', {
      id: 'visibility',
      cell: info => {
        const visibility = info.getValue();
        const visibilityKeyToText = {
          ORGANISATION: 'Organisation',
          ONLY_YOU: 'Only You',
        };
        const dotColor =
          visibility === 'ORGANISATION' ? 'bg-accent-dark-500' : 'bg-gray-500';
        return (
          <StatusTag dotColor={dotColor}>
            {visibilityKeyToText[visibility]}
          </StatusTag>
        );
      },
      header: () => <span className="text-xs text-gray-600">Visibility</span>,
      enableSorting: true,
    }),
    columnHelper.accessor('url', {
      id: 'url',
      cell: path => {
        return <Link to={path.getValue()}>Run</Link>;
      },
      header: () => <span className="text-xs text-gray-600">Actions</span>,
      enableSorting: true,
    }),
  ];

  return (
    <Box>
      <div className="flex justify-between">
        <p className="text-lg font-semibold text-primary-900">Reports</p>
      </div>
      <Table columns={columns} data={templates} paginationType="auto" />
    </Box>
  );
}

export { ReportsTable };
